import { getAccessToken } from 'helpers/api';
import { isAvailableArray } from 'helpers/arrayUtils';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRouteConfig, updateRouteConfig } from 'redux/actions';
import { decrypt, encrypt } from 'helpers/cryptoUtils';
import { createLCStorageKey } from 'helpers/keyUtils';
import encUtf8 from 'crypto-js/enc-utf8';

/**
 * @typedef {import('settings/model/redux').ReduxActionOptions} ReduxActionOptions
 */

/**
 * @typedef {Object} Page
 * @property {string} path
 * @property {string} name
 *
 * @typedef {Object} MenuItem
 * @property {string} path
 * @property {string} name
 * @property {MenuItem[]} children
 */

/**
 * @typedef {Object} RouteConfig
 * @property {string} homePage
 * @property {Page[]} pages
 * @property {MenuItem[]} menu
 */

/**
 * @typedef {Object} Output
 * @property {RouteConfig} config
 * @property {() => string} getHomePage
 * @property {() => boolean} hasMenu
 * @property {() => MenuItem[]} getMenu
 * @property {() => Page[]} getPages
 * @property {() => RouteConfig} getLocalStorageRouteConfig
 * @property {() => boolean} setLocalStorageRouteConfig
 * @property {(options?: ReduxActionOptions) => void} fetchConfig
 * @property {(config: RouteConfig) => void} setReduxRouteConfig
 */

const ROUTE_CONFIG_KEY = createLCStorageKey('r_c');

/**
 *
 * @returns {RouteConfig?}
 */
const getLocalStorageRouteConfig = () => {
  try {
    const encryptedData = localStorage.getItem(ROUTE_CONFIG_KEY);
    if (encryptedData) {
      const bytes = decrypt(encryptedData);
      const data = JSON.parse(bytes.toString(encUtf8));

      const accessToken = getAccessToken();

      if (accessToken && data?.access_token === accessToken) {
        return isAvailableArray(data.config?.menu) ? data.config : null;
      }
    }
  } catch (error) {
    console.error(error);
  }

  localStorage.removeItem(ROUTE_CONFIG_KEY);
  return null;
};

/**
 * @param {RouteConfig} config
 * @returns {void}
 */
const setLocalStorageRouteConfig = (config) => {
  const accessToken = getAccessToken();

  const data = {
    access_token: accessToken,
    config,
  };

  const cipherText = encrypt(JSON.stringify(data)).toString();

  localStorage.setItem(ROUTE_CONFIG_KEY, cipherText);
};

/** @return {Output} */
function useRouteConfig() {
  const dispatch = useDispatch();
  const config = useSelector((state) => state.Common.routeConfig);

  const methods = useMemo(
    () => ({
      hasMenu: () => config != null && config.menu.length > 0,
      getMenu: () => (config != null ? config.menu : []),
      getPages: () => (config != null ? config.pages : []),
      getHomePage: () => (config != null ? config.homePage : '/dashboard'),
    }),
    [config]
  );

  const fetchConfig = useCallback(
    /**
     * @param {ReduxActionOptions | null} [options] - Options for the Redux action.
     */
    (options) => {
      dispatch(fetchRouteConfig(options));
    },
    [dispatch]
  );

  const setReduxRouteConfig = useCallback(
    /**
     * @param {RouteConfig} config
     */
    (config) => {
      if (config) {
        dispatch(updateRouteConfig(config));
      }
    },
    [dispatch]
  );

  return {
    config,
    ...methods,
    getLocalStorageRouteConfig,
    setLocalStorageRouteConfig,
    fetchConfig,
    setReduxRouteConfig,
  };
}

export default useRouteConfig;
