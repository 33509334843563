// @flow
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { handleLoading } from 'redux/layout/actions';
import { subscribeNotificationToken, unsubscribeNotificationToken } from 'helpers/authUtils';
import toast from 'react-hot-toast';
import * as type from './constants';
import * as actions from './actions';
import * as api from './api';

const HN_DN_HCM_IDS = ['01TTT', '48TTT', '79TTT'];

export const addressSort = (data, specials = []) => {
  const specialsData = data.filter((item) => specials.includes(item.id));
  const dataSort = data.filter((item) => !specials.includes(item.id)).sort((a, b) => a.name.localeCompare(b.name));

  return specialsData.concat(dataSort);
};

/* Admin sagas */
function* fetchRegions() {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchRegions);

    yield put(actions.fetchRegionsSuccessed(addressSort(response)));
  } catch (error) {
    yield put(actions.fetchRegionsFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchProvinces(action) {
  yield put(handleLoading(true));
  const { callback = () => {} } = action.payload;

  try {
    const response = yield call(api.fetchProvinces);
    const result = addressSort(response, HN_DN_HCM_IDS);

    yield put(actions.fetchProvincesSuccessed(result));
    callback(result);
  } catch (error) {
    yield put(actions.fetchProvincesFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchProvincesByRegion(action) {
  yield put(handleLoading(true));
  const { callback = () => {}, regionId } = action.payload;

  try {
    const response = yield call(api.fetchProvincesByRegion, regionId);
    const result = addressSort(response, HN_DN_HCM_IDS);

    yield put(actions.fetchProvincesByRegionSuccessed(result));
    callback(result);
  } catch (error) {
    yield put(actions.fetchProvincesByRegionFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchDistricts(action) {
  yield put(handleLoading(true));
  const { provinceId, callback = () => {} } = action.payload;

  try {
    const response = yield call(api.fetchDistricts, provinceId);
    const result = addressSort(response);

    yield put(actions.fetchDistrictsSuccessed(result));
    callback(result);
  } catch (error) {
    yield put(actions.fetchDistrictsFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchWards(action) {
  yield put(handleLoading(true));
  const { districtId, callback = () => {} } = action.payload;

  try {
    const response = yield call(api.fetchWards, districtId);
    const result = addressSort(response);

    yield put(actions.fetchWardsSuccessed(result));
    callback(result);
  } catch (error) {
    yield put(actions.fetchWardsFailed(error));
  }

  yield put(handleLoading(false));
}

function* clearDistricts(action) {
  yield put(actions.clearDistrictsSuccessed());
}

function* clearWards(action) {
  yield put(actions.clearWardsSuccessed());
}

function* setRegion(action) {
  yield put(handleLoading(true));

  try {
    yield call(api.setRegion, action.payload);
    toast.success('Tạo miền thành công');
    yield put(actions.setRegionSuccessed());
    yield put(actions.fetchRegions());
  } catch (error) {
    yield put(actions.setRegionFailed(error));
    toast.error('Tạo miền thất bại');
  }

  yield put(handleLoading(false));
}

function* setProvince(action) {
  yield put(handleLoading(true));

  try {
    yield call(api.setProvince, action.payload);
    toast.success('Tạo Tỉnh/Thành phố thành công');
    yield put(actions.setProvinceSuccessed());
    yield put(actions.fetchProvincesByRegion(action.payload.regionId));
  } catch (error) {
    toast.error('Tạo Tỉnh/Thành phố thất bại');
    yield put(actions.setProvinceFailed(error));
  }

  yield put(handleLoading(false));
}

function* setDistrict(action) {
  yield put(handleLoading(true));

  try {
    yield call(api.setDistrict, action.payload);
    toast.success('Tạo Quận/Huyện thành công');
    yield put(actions.setDistrictSuccessed());
    yield put(actions.fetchDistricts(action.payload.provinceId));
  } catch (error) {
    toast.error('Tạo Quận/Huyện thất bại');
    yield put(actions.setDistrictFailed(error));
  }

  yield put(handleLoading(false));
}

function* setWard(action) {
  yield put(handleLoading(true));

  try {
    yield call(api.setWard, action.payload);
    toast.success('Tạo Phường/Xã thành công');
    yield put(actions.setWardSuccessed());
    yield put(actions.fetchWards(action.payload.districtId));
  } catch (error) {
    toast.error('Tạo Phường/Xã thất bại');
    yield put(actions.setWardFailed(error));
  }

  yield put(handleLoading(false));
}

function* deleteRegion(action) {
  yield put(handleLoading(true));

  try {
    yield call(api.deleteRegion, action.payload);
    yield put(actions.deleteRegionSuccessed());
    yield put(actions.fetchRegions());
  } catch (error) {
    yield put(actions.deleteRegionFailed(error));
  }

  yield put(handleLoading(false));
}

function* deleteProvince(action) {
  // yield put(handleLoading(true));

  try {
    yield call(api.deleteProvince, action.payload);
    yield put(actions.deleteProvinceSuccessed());
    // yield put(actions.fetchRegions())

    toast.success('Xoá Tỉnh/Thành phố thành công');
  } catch (error) {
    yield put(actions.deleteCategoryFailed(error));
    toast.success('Xoá Tỉnh/Thành phố thất bại');
  }

  // yield put(handleLoading(false));
}

function* deleteDistrict(action) {
  // yield put(handleLoading(true));

  try {
    yield call(api.deleteDistrict, action.payload);
    yield put(actions.deleteDistrictSuccessed());
    // yield put(actions.fetchRegions())

    toast.success('Xoá Quận/Huyện phố thành công');
  } catch (error) {
    yield put(actions.deleteCategoryFailed(error));
    toast.success('Xoá Quận/Huyện phố thất bại');
  }

  // yield put(handleLoading(false));
}

function* deleteWard(action) {
  // yield put(handleLoading(true));

  try {
    yield call(api.deleteWard, action.payload);
    yield put(actions.deleteWardSuccessed());
    // yield put(actions.fetchRegions())

    toast.success('Xoá Phường/Xã phố thành công');
  } catch (error) {
    yield put(actions.deleteCategoryFailed(error));
    toast.success('Xoá Phường/Xã phố thất bại');
  }

  // yield put(handleLoading(false));
}

export function* watchAddress() {
  yield takeEvery(type.FETCH_REGIONS, fetchRegions);
  yield takeEvery(type.FETCH_PROVINCES, fetchProvinces);
  yield takeEvery(type.FETCH_DISTRICTS, fetchDistricts);
  yield takeEvery(type.FETCH_WARDS, fetchWards);
  yield takeEvery(type.CLEAR_DISTRICTS, clearDistricts);
  yield takeEvery(type.CLEAR_WARDS, clearWards);
  yield takeEvery(type.FETCH_PROVINCES_BY_REGION, fetchProvincesByRegion);
  yield takeEvery(type.SET_REGION, setRegion);
  yield takeEvery(type.SET_PROVINCE, setProvince);
  yield takeEvery(type.SET_DISTRICT, setDistrict);
  yield takeEvery(type.SET_WARD, setWard);
  yield takeEvery(type.DELETE_REGION, deleteRegion);
  yield takeEvery(type.DELETE_PROVINCE, deleteProvince);
  yield takeEvery(type.DELETE_DISTRICT, deleteDistrict);
  yield takeEvery(type.DELETE_WARD, deleteWard);
}

function* fetchCategories(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchCategories);

    yield put(actions.fetchCommonCategorySuccessed(response));
  } catch (error) {
    yield put(actions.fetchCommonCategoryFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchCategoryValues(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchCategoryValues, action.payload);
    yield put(actions.fetchCategoryValuesSuccessed(response));
  } catch (error) {
    yield put(actions.fetchCategoryValuesFailed(error));
  }

  yield put(handleLoading(false));
}

function* createCategory(action) {
  yield put(handleLoading(true));

  try {
    yield call(api.createCategory, action.payload);
    toast.success('Tạo mới thành công');
    yield put(actions.createCategorySuccessed());
    yield put(actions.fetchCategoryValues(action.payload.categoryId));
  } catch (error) {
    toast.error('Tạo mới thất bại');
    yield put(actions.createCategoryFailed(error));
  }

  yield put(handleLoading(false));
}

function* updateCategory(action) {
  yield put(handleLoading(true));

  try {
    const res = yield call(api.updateCategory, action.payload);
    yield put(actions.updateCategorySuccessed(res));
    toast.success('Tạo mới thành công');
    // yield put(actions.fetchCategoryValues(action.payload.categoryId))
  } catch (error) {
    toast.error('Tạo mới thất bại');
    yield put(actions.updateCategoryFailed(error));
  }

  yield put(handleLoading(false));
}

function* deleteCategory(action) {
  yield put(handleLoading(true));

  const { id } = action.payload;

  try {
    yield call(api.deleteCategory, id);
    yield put(actions.deleteCategorySuccessed(id));
    toast.success('Xoá thành công');
    // yield put(actions.fetchCategoryValues(categoryId))
  } catch (error) {
    yield put(actions.deleteCategoryFailed(error));
    toast.success('Xoá thất bại');
  }

  yield put(handleLoading(false));
}

export function* watchCategory() {
  yield takeEvery(type.FETCH_COMMON_CATEGORY, fetchCategories);
  yield takeEvery(type.FETCH_CATEGORY_VALUES, fetchCategoryValues);
  yield takeEvery(type.CREATE_CATEGORY, createCategory);
  yield takeEvery(type.DELETE_CATEGORY, deleteCategory);
  yield takeEvery(type.UPDATE_CATEGORY, updateCategory);
}

function* fetchIPGeo(action) {
  yield put(handleLoading(true));

  try {
    const ipResponse = yield call(api.fetchIP);
    const geoResponse = yield call(api.fetchIPGeo, ipResponse.ip);

    yield put(actions.fetchIPGeoSuccessed(geoResponse));
  } catch (error) {
    yield put(actions.fetchIPGeoFailed(error));
  }

  yield put(handleLoading(false));
}

export function* watchIpGeo() {
  yield takeEvery(type.FETCH_IP_GEO, fetchIPGeo);
}

function* fetchAppList() {
  try {
    const response = yield call(api.fetchAppList);

    yield put(actions.fetchAppListSuccess(response));
  } catch (error) {
    // yield put(actions.fetchAppListFailed(error));
  }
}

export function* watchAppList() {
  yield takeEvery(type.FETCH_APP_LIST, fetchAppList);
}

function* fetchNotifications(action) {
  const { callback = () => {} } = action.payload;
  try {
    const response = yield call(api.fetchNotifications);

    yield put(actions.fetchNotificationsSuccess(response));
  } catch (error) {
    // yield put(actions.fetchNotificationsFailed(error));
  }
  callback && callback();
}

function* fetchUnseenNotifications(action) {
  const { callback = () => {} } = action.payload;
  try {
    const response = yield call(api.fetchUnseenNotifications);

    yield put(actions.fetchUnseenNotificationsSuccess(response));
  } catch (error) {
    // yield put(actions.fetchNotificationsFailed(error));
  }
  callback && callback();
}

function* subscribeUser(action) {
  const { token } = action.payload;
  try {
    yield call(api.subscribeUser, [token]);
    subscribeNotificationToken(token);
    yield put(actions.subscribeUserSuccess(token));
  } catch (error) {
    yield put(actions.subscribeUserFailed(error));
  }
}

function* unsubscribeUser(action) {
  const { token } = action.payload;
  try {
    yield call(api.unsubscribeUser, [token]);
    unsubscribeNotificationToken();
    yield put(actions.unsubscribeUserSuccess());
  } catch (error) {
    yield put(actions.unsubscribeUserFailed(error));
  }
}

function* updateNotification(action) {
  const { id } = action.payload;
  try {
    yield call(api.updateNotification, id);
  } catch (error) {
    console.error(error);
  }
}

export function* watchNotifications() {
  yield takeEvery(type.SUBSCRIBE_USER, subscribeUser);
  yield takeEvery(type.UNSUBSCRIBE_USER, unsubscribeUser);
  yield takeEvery(type.UPDATE_NOTIFICATION, updateNotification);
  yield takeEvery(type.FETCH_NOTIFICATIONS, fetchNotifications);
  yield takeEvery(type.FETCH_UNSEEN_NOTIFICATIONS, fetchUnseenNotifications);
}

function* clearCache(action) {
  const { params, options = {} } = action.payload;
  const { loading, onSuccess } = options;

  loading && loading(true);
  try {
    const response = yield call(api.clearCache, params);
    onSuccess && onSuccess(response);
  } catch (error) {
    console.error(error);
  }
  loading && loading(false);
}

function* fetchRouteConfig(action) {
  const { options = {} } = action.payload;
  const { loading, onSuccess } = options;

  loading && loading(true);
  try {
    const response = yield call(api.fetchRouteConfig);
    yield put(actions.fetchRouteConfigSuccessed(response));
    onSuccess && onSuccess(response);
  } catch (error) {
    console.error(error);
  }
  loading && loading(false);
}

function* watchOthers() {
  yield takeLatest(type.FETCH_ROUTE_CONFIG, fetchRouteConfig);
  yield takeLatest(type.CLEAR_CACHE, clearCache);
}

function* commonSaga() {
  yield all([
    fork(watchAddress),
    fork(watchCategory),
    fork(watchIpGeo),
    fork(watchAppList),
    fork(watchNotifications),
    fork(watchOthers),
  ]);
}

export default commonSaga;
