import * as type from './constants';

/* Accounts actions */
export const fetchAccounts = (pagination) => ({ type: type.FETCH_ACCOUNTS, payload: pagination });
export const fetchAccountsSuccessed = (data) => ({
  type: type.FETCH_ACCOUNTS_SUCCESSED,
  payload: data,
});
export const fetchAccountsFailed = (error) => ({
  type: type.FETCH_ACCOUNTS_FAILED,
  payload: error,
});
export const clearAccounts = () => ({ type: type.CLEAR_ACCOUNTS });

export const fetchPartners = (pagination, loading = () => {}) => ({
  type: type.FETCH_PARTNERS,
  payload: { pagination, loading },
});
export const fetchPartnersSuccessed = (data) => ({
  type: type.FETCH_PARTNERS_SUCCESSED,
  payload: data,
});
export const fetchPartnersFailed = (error) => ({
  type: type.FETCH_PARTNERS_FAILED,
  payload: error,
});
export const clearPartners = () => ({ type: type.CLEAR_PARTNERS });

export const fetchPartnersByFilter = (filter, pagination, loading = () => {}) => ({
  type: type.FETCH_PARTNERS_BY_FILTER,
  payload: { filter, pagination, loading },
});

export const fetchPartnerDetail = (id) => ({ type: type.FETCH_PARTNER_DETAIL, payload: id });
export const fetchPartnerDetailSuccessed = (data) => ({
  type: type.FETCH_PARTNER_DETAIL_SUCCESSED,
  payload: data,
});
export const fetchPartnerDetailFailed = (error) => ({
  type: type.FETCH_PARTNER_DETAIL_FAILED,
  payload: error,
});

export const createPartner = (data, setStatus = () => {}) => ({
  type: type.CREATE_PARTNER,
  payload: { data, setStatus },
});
export const createPartnerSuccessed = (data) => ({
  type: type.CREATE_PARTNER_SUCCESSED,
  payload: data,
});
export const createPartnerFailed = (error) => ({
  type: type.CREATE_PARTNER_FAILED,
  payload: error,
});

export const updatePartner = (id, data, setStatus = () => {}) => ({
  type: type.UPDATE_PARTNER,
  payload: { id, data, setStatus },
});
export const updatePartnerSuccessed = (data) => ({
  type: type.UPDATE_PARTNER_SUCCESSED,
  payload: data,
});
export const updatePartnerFailed = (error) => ({
  type: type.UPDATE_PARTNER_FAILED,
  payload: error,
});

export const fetchPayments = (pagination) => ({ type: type.FETCH_PAYMENTS, payload: pagination });
export const fetchPaymentsSuccessed = (data) => ({
  type: type.FETCH_PAYMENTS_SUCCESSED,
  payload: data,
});
export const fetchPaymentsFailed = (error) => ({
  type: type.FETCH_PAYMENTS_FAILED,
  payload: error,
});
export const clearPayments = () => ({ type: type.CLEAR_PAYMENTS });

export const fetchPartnersByGroup = (groupId, callback = () => {}) => ({
  type: type.FETCH_PARTNERS_BY_GROUP,
  payload: { groupId, callback },
});
export const fetchPartnersByGroupSuccessed = (data) => ({
  type: type.FETCH_PARTNERS_BY_GROUP_SUCCESSED,
  payload: data,
});
export const fetchPartnersByGroupFailed = (error) => ({
  type: type.FETCH_PARTNERS_BY_GROUP_FAILED,
  payload: error,
});

export const fetchLeadValid = (data) => ({ type: type.FETCH_LEAD_VALID, payload: data });
export const fetchLeadValidSuccessed = (data) => ({
  type: type.FETCH_LEAD_VALID_SUCCESSED,
  payload: data,
});
export const fetchLeadValidFailed = (error) => ({
  type: type.FETCH_LEAD_VALID_FAILED,
  payload: error,
});

export const fetchLeadStatus = (data) => ({ type: type.FETCH_LEAD_STATUS, payload: data });
export const fetchLeadStatusSuccessed = (data) => ({
  type: type.FETCH_LEAD_STATUS_SUCCESSED,
  payload: data,
});
export const fetchLeadStatusFailed = (error) => ({
  type: type.FETCH_LEAD_STATUS_FAILED,
  payload: error,
});

export const fetchLeadReportDayCount = (data) => ({
  type: type.FETCH_LEAD_REPORT_DAY_COUNT,
  payload: data,
});
export const fetchLeadReportDayCountSuccessed = (data) => ({
  type: type.FETCH_LEAD_REPORT_DAY_COUNT_SUCCESSED,
  payload: data,
});
export const fetchLeadReportDayCountFailed = (error) => ({
  type: type.FETCH_LEAD_REPORT_DAY_COUNT_FAILED,
  payload: error,
});

export const fetchLeadDisbursement = (data) => ({
  type: type.FETCH_LEAD_DISBURSEMENT,
  payload: data,
});
export const fetchLeadDisbursementSuccessed = (data) => ({
  type: type.FETCH_LEAD_DISBURSEMENT_SUCCESSED,
  payload: data,
});
export const fetchLeadDisbursementFailed = (error) => ({
  type: type.FETCH_LEAD_DISBURSEMENT_FAILED,
  payload: error,
});

export const fetchEffectivePartner = (data) => ({
  type: type.GET_EFFECTIVE_PARTNER,
  payload: data,
});
export const fetchEffectivePartnerSuccessed = (data) => ({
  type: type.GET_EFFECTIVE_PARTNER_SUCCESSED,
  payload: data,
});
export const fetchEffectivePartnerFailed = (error) => ({
  type: type.GET_EFFECTIVE_PARTNER_FAILED,
  payload: error,
});
