import { fetchJSON, getAccessToken } from 'helpers/api';
import { ENP } from 'constants/api';

/* Loan debt API */
export const fetchCampaigns = (params) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/campaign/get-all`, options, params);
};

export const fetchCampaignsByFilter = (filter, params) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(filter),
  };

  return fetchJSON(`${ENP.LEAD_API}/campaign/filter`, options, params);
};

export const fetchCampaignDetail = (code) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/campaign/code/${code}`, options);
};

export const createCampaign = (data) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.LEAD_API}/campaign/create`, options);
};

export const updateCampaign = (campaignCode, data) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.LEAD_API}/campaign/code/${campaignCode}`, options);
};

export const deleteCampaign = (campaignCode) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: 'DELETE',
  };

  return fetchJSON(`${ENP.LEAD_API}/campaign/code/${campaignCode}`, options);
};

export const fetchCampaignCode = (cate, campaignId) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.MARKETING_API}/v1/campaign/code/${campaignId}/${cate}`, options);
};

export const createCampaignCode = (data) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.MARKETING_API}/v1/campaign/code/create`, options);
};

export const updateCampaignCode = (codeId, data) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.MARKETING_API}/v1/campaign/code/${codeId}`, options);
};

export const fetchCampaignGroups = (params) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.MARKETING_API}/v1/campaign/group/get-all`, options, params);
};

export const fetchCampaignGroupDetail = (groupId) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.MARKETING_API}/v1/campaign/group/${groupId}`, options);
};

export const createCampaignGroup = (data) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.MARKETING_API}/v1/campaign/group/create`, options);
};

export const updateCampaignGroup = (groupId, data) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.MARKETING_API}/v1/campaign/group/${groupId}`, options);
};

// Campaign partner
export const fetchCampaignPartners = (campaignCode) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/campaign/partner/${campaignCode}`, options);
};

export const createCampaignPartner = (data) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.LEAD_API}/campaign/create/partner`, options);
};

export const updateCampaignPartner = (data) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.LEAD_API}/campaign/update/partner`, options);
};

export const deleteCampaignPartner = (campaignCode, partnerCode) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: 'DELETE',
  };

  return fetchJSON(`${ENP.LEAD_API}/campaign/code/${campaignCode}/partner/${partnerCode}`, options);
};

export const updateCampaignStatus = (campaignCode, newStatus) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: 'PUT',
  };

  if (newStatus == null) throw Error('UNAVAILABLE_CAMPAIGN_STATUS');

  let theStatus = '';
  switch (newStatus) {
    case 'activate':
      theStatus = 'activate';
      break;
    case 'deactivate':
      theStatus = 'deactivate';
      break;
    case 'finished':
      theStatus = 'finish';
      break;
    case 'canceled':
      theStatus = 'cancel';
      break;
    default:
      throw Error('UNAVAILABLE_CAMPAIGN_STATUS');
  }

  return fetchJSON(`${ENP.LEAD_API}/campaign/${theStatus}/code/${campaignCode}`, options);
};

export const checkExistCampaignByCode = (campaignCode) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/campaign/code/${campaignCode}/existed`, options);
};

export const reportTopPartnerCount = (campaignCode) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/campaign/report/top-partner/count/${campaignCode}`, options);
};

export const leadStatusCount = (campaignCode) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/campaign/report/lead-status/count/${campaignCode}`, options);
};

export const dayCountCount = (campaignCode) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/campaign/report/day/count/${campaignCode}`, options);
};

export const disbursement = (campaignCode) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/campaign/report/disbursement/${campaignCode}`, options);
};

export const leadValidCount = (campaignCode) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/campaign/report/lead-valid/count/${campaignCode}`, options);
};
