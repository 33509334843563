import { lazy } from 'react';
import { LocalGroceryStoreOutlined } from '@mui/icons-material';
import { LEAD_SVC, LEAD_VIEW_LIST } from 'constants/roles';

const Lead = lazy(() => import('pages/lead/manage'));
const Unallocated = lazy(() => import('pages/lead/unallocated'));
const Following = lazy(() => import('pages/lead/following'));
const BlackLead = lazy(() => import('pages/lead/blackList'));
const CloseLead = lazy(() => import('pages/lead/close'));
const ClaimLead = lazy(() => import('pages/lead/claim'));

// Detail
const LeadDetail = lazy(() => import('pages/lead/detail'));

/**
 * @typedef {import('router/routes').NxRoute} NxRoute
 * @type {NxRoute}
 */
const sellRoutes = {
  path: '/sell',
  name: 'menuConfig:sellConfig:sell',
  icon: LocalGroceryStoreOutlined,
  children: [
    {
      path: '/sell/lead',
      name: 'menuConfig:sellConfig:lead',
      Component: Lead,
      resource: LEAD_SVC,
      roles: [LEAD_VIEW_LIST],
    },
    {
      path: '/sell/lead/:id',
      Component: LeadDetail,
      exact: false,
    },

    {
      path: '/sell/unallocated',
      name: 'menuConfig:sellConfig:unallocated',
      Component: Unallocated,
      resource: LEAD_SVC,
      roles: [LEAD_VIEW_LIST],
    },
    {
      path: '/sell/unallocated/:id',
      Component: LeadDetail,
      exact: false,
    },

    {
      path: '/sell/following',
      name: 'menuConfig:sellConfig:following',
      Component: Following,
      resource: LEAD_SVC,
      roles: [LEAD_VIEW_LIST],
    },
    {
      path: '/sell/following/:id',
      Component: LeadDetail,
      exact: false,
    },

    {
      path: '/sell/blacklist',
      name: 'menuConfig:sellConfig:blackList',
      Component: BlackLead,
      resource: LEAD_SVC,
      roles: [LEAD_VIEW_LIST],
    },
    {
      path: '/sell/blacklist/:id',
      Component: LeadDetail,
      exact: false,
    },

    {
      path: '/sell/close',
      name: 'menuConfig:sellConfig:closeLead',
      Component: CloseLead,
      resource: LEAD_SVC,
      roles: [LEAD_VIEW_LIST],
    },
    {
      path: '/sell/close/:id',
      Component: LeadDetail,
      exact: false,
    },

    {
      path: '/sell/claim',
      name: 'menuConfig:sellConfig:claim',
      Component: ClaimLead,
      resource: LEAD_SVC,
      roles: [LEAD_VIEW_LIST],
    },
    {
      path: '/sell/claim/:id',
      Component: LeadDetail,
      exact: false,
    },
  ],
};

export default sellRoutes;
