import { ComponentType, ReactElement } from 'react';
import { isUserAuthenticated } from 'helpers/authUtils';
import { Navigate, useParams } from 'react-router-dom';

/**
 * A custom public route component that wraps the Route component and checks for
 * user authentication.
 * If the user is already authenticated, they are redirected to the homepage.
 * Otherwise, it renders the passed Component.
 */

interface PublicRouteProps {
  Component: ComponentType;
}

function PublicRoute({ Component }: PublicRouteProps): ReactElement {
  const props = useParams();

  if (isUserAuthenticated()) {
    return <Navigate to="/" />;
  }

  return <Component {...props} />;
}

export default PublicRoute;
