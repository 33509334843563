import * as type from './constants';

export const fetchLeadSettingFailed = (params) => ({
  type: type.LEAD_SETTINGS_FETCH_FAILED,
  payload: params,
});

export const fetchLeadSettingChecklist = () => ({
  type: type.LEAD_SETTINGS_FETCH_CHECKLIST,
  payload: {},
});

export const fetchLeadSettingChecklistSuccessed = (params) => ({
  type: type.LEAD_SETTINGS_FETCH_CHECKLIST_SUCCESSED,
  payload: params,
});

export const createLeadSettingChecklist = (data, setStatus = () => {}) => ({
  type: type.LEAD_SETTINGS_CREATE_CHECKLIST,
  payload: { data, setStatus },
});

export const createLeadSettingChecklistFailed = (error) => ({
  type: type.LEAD_SETTINGS_CREATE_CHECKLIST_FAILED,
  payload: error,
});

export const updateLeadSettingChecklist = (name, data, setStatus = () => {}) => ({
  type: type.LEAD_SETTINGS_UPDATE_CHECKLIST,
  payload: { name, data, setStatus },
});

export const updateLeadSettingChecklistFailed = (error) => ({
  type: type.LEAD_SETTINGS_UPDATE_CHECKLIST_FAILED,
  payload: error,
});

export const deleteLeadSettingChecklist = (name, setStatus = () => {}) => ({
  type: type.LEAD_SETTINGS_DELETE_CHECKLIST,
  payload: { name, setStatus },
});

export const deleteLeadSettingChecklistFailed = (error) => ({
  type: type.LEAD_SETTINGS_DELETE_CHECKLIST_FAILED,
  payload: error,
});

export const fetchLeadSettingAssignStrategy = () => ({
  type: type.LEAD_SETTINGS_FETCH_ASSIGN_STRATEGY,
});

export const fetchLeadSettingAssignStrategySuccessed = (payload) => ({
  type: type.LEAD_SETTINGS_FETCH_ASSIGN_STRATEGY_SUCCESSED,
  payload,
});

export const fetchLeadSettingAssignStrategyFailed = (payload) => ({
  type: type.LEAD_SETTINGS_FETCH_ASSIGN_STRATEGY_FAILED,
  payload,
});

export const updateLeadSettingAssignStrategy = (payload) => ({
  type: type.LEAD_SETTINGS_UPDATE_ASSIGN_STRATEGY,
  payload,
});

export const updateLeadSettingAssignStrategySuccessed = (payload) => ({
  type: type.LEAD_SETTINGS_UPDATE_ASSIGN_STRATEGY_SUCCESSED,
  payload,
});

export const updateLeadSettingAssignStrategyFailed = (payload) => ({
  type: type.LEAD_SETTINGS_UPDATE_ASSIGN_STRATEGY_FAILED,
  payload,
});

/* Lead actions */

export const deleteLead = (leadId) => ({
  type: type.DELETE_LEAD,
  payload: leadId,
});
export const deleteLeadSuccessed = (data) => ({
  type: type.DELETE_LEAD_SUCCESSED,
  payload: data,
});
export const deleteLeadFailed = (error) => ({
  type: type.DELETE_LEAD_FAILED,
  payload: error,
});

export const updateLeadStatus = (leadId, data) => ({
  type: type.UPDATE_LEAD_STATUS,
  payload: { leadId, data },
});
export const updateLeadStatusSuccessed = (data) => ({
  type: type.UPDATE_LEAD_STATUS_SUCCESSED,
  payload: data,
});
export const updateLeadStatusFailed = (error) => ({
  type: type.UPDATE_LEAD_STATUS_FAILED,
  payload: error,
});

export const updateLeadAssignee = (leadId, data) => ({
  type: type.UPDATE_LEAD_ASSIGNEE,
  payload: { leadId, data },
});
export const updateLeadAssigneeSuccessed = (data) => ({
  type: type.UPDATE_LEAD_ASSIGNEE_SUCCESSED,
  payload: data,
});
export const updateLeadAssigneeFailed = (error) => ({
  type: type.UPDATE_LEAD_ASSIGNEE_FAILED,
  payload: error,
});

export const fetchLeadListStartStatus = () => ({
  type: type.FETCH_LEAD_LIST_START_STATUS,
});
export const fetchLeadListStartStatusSuccessed = (data) => ({
  type: type.FETCH_LEAD_LIST_START_STATUS_SUCCESSED,
  payload: data,
});
export const fetchLeadListStartStatusFailed = (error) => ({
  type: type.FETCH_LEAD_LIST_START_STATUS_FAILED,
  payload: error,
});

export const fetchLeadListStatus = () => ({
  type: type.FETCH_LEAD_LIST_STATUS,
});
export const fetchLeadListStatusSuccessed = (data) => ({
  type: type.FETCH_LEAD_LIST_STATUS_SUCCESSED,
  payload: data,
});
export const fetchLeadListStatusFailed = (error) => ({
  type: type.FETCH_LEAD_LIST_STATUS_FAILED,
  payload: error,
});

export const fetchLeadListResult = () => ({
  type: type.FETCH_LEAD_LIST_RESULT,
});
export const fetchLeadListResultSuccessed = (data) => ({
  type: type.FETCH_LEAD_LIST_RESULT_SUCCESSED,
  payload: data,
});
export const fetchLeadListResultFailed = (error) => ({
  type: type.FETCH_LEAD_LIST_RESULT_FAILED,
  payload: error,
});

export const updateLeadDemand = (leadId, data, setStatus = (value) => {}) => ({
  type: type.UPDATE_LEAD_DEMAND,
  payload: { leadId, data, setStatus },
});
export const updateLeadDemandSuccessed = (data) => ({
  type: type.UPDATE_LEAD_DEMAND_SUCCESSED,
  payload: data,
});
export const updateLeadDemandFailed = (error) => ({
  type: type.UPDATE_LEAD_DEMAND_FAILED,
  payload: error,
});

export const fetchLeadS37 = (leadId) => ({
  type: type.FETCH_LEAD_S37,
  payload: leadId,
});
export const fetchLeadS37Successed = (data) => ({
  type: type.FETCH_LEAD_S37_SUCCESSED,
  payload: data,
});
export const fetchLeadS37Failed = (error) => ({
  type: type.FETCH_LEAD_S37_FAILED,
  payload: error,
});

export const fetchLeadCIC = (leadId) => ({
  type: type.FETCH_LEAD_CIC,
  payload: leadId,
});
export const fetchLeadCICSuccessed = (data) => ({
  type: type.FETCH_LEAD_CIC_SUCCESSED,
  payload: data,
});
export const fetchLeadCICFailed = (error) => ({
  type: type.FETCH_LEAD_CIC_FAILED,
  payload: error,
});

export const updateLeadS37 = (leadId, data, callback) => ({
  type: type.UPDATE_LEAD_S37,
  payload: { leadId, data, callback },
});
export const updateLeadS37Successed = (data) => ({
  type: type.UPDATE_LEAD_S37_SUCCESSED,
  payload: data,
});
export const updateLeadS37Failed = (error) => ({
  type: type.UPDATE_LEAD_S37_FAILED,
  payload: error,
});

export const updateLeadCIC = (leadId, data, callback) => ({
  type: type.UPDATE_LEAD_CIC,
  payload: { leadId, data, callback },
});
export const updateLeadCICSuccessed = (data) => ({
  type: type.UPDATE_LEAD_CIC_SUCCESSED,
  payload: data,
});
export const updateLeadCICFailed = (error) => ({
  type: type.UPDATE_LEAD_CIC_FAILED,
  payload: error,
});

export const assignLead = (assignee, data) => ({
  type: type.ASSIGN_LEAD,
  payload: { assignee, data },
});
export const assignLeadSuccessed = (data) => ({
  type: type.ASSIGN_LEAD_SUCCESSED,
  payload: data,
});
export const assignLeadFailed = (error) => ({
  type: type.ASSIGN_LEAD_FAILED,
  payload: error,
});

export const autoAssignLead = (callback) => ({
  type: type.AUTO_ASSIGN_LEAD,
  payload: { callback },
});
export const autoAssignLeadSuccessed = (data) => ({
  type: type.AUTO_ASSIGN_LEAD_SUCCESSED,
  payload: data,
});
export const autoAssignLeadFailed = (error) => ({
  type: type.AUTO_ASSIGN_LEAD_FAILED,
  payload: error,
});

export const setOpenCreateLead = (status) => ({
  type: type.SET_OPEN_CREATE_LEAD,
  payload: status,
});

export const fetchLeadDocuments = (leadId, pagination) => ({
  type: type.FETCH_LEAD_DOCUMENTS,
  payload: { leadId, pagination },
});
export const fetchLeadDocumentsSuccessed = (data) => ({
  type: type.FETCH_LEAD_DOCUMENTS_SUCCESSED,
  payload: data,
});
export const fetchLeadDocumentsFailed = (error) => ({
  type: type.FETCH_LEAD_DOCUMENTS_FAILED,
  payload: error,
});

export const fetchRequiredDocumentTypes = (productCode) => ({
  type: type.FETCH_REQUIRED_DOCUMENT_TYPES,
  payload: { productCode },
});
export const fetchRequiredDocumentTypesSuccessed = (data) => ({
  type: type.FETCH_REQUIRED_DOCUMENT_TYPES_SUCCESSED,
  payload: data,
});
export const fetchRequiredDocumentTypesFailed = (error) => ({
  type: type.FETCH_REQUIRED_DOCUMENT_TYPES_FAILED,
  payload: error,
});

export const uploadLeadDocument = (leadId, documentType, documentName, file, setStatus = () => {}) => ({
  type: type.UPLOAD_LEAD_DOCUMENT,
  payload: { leadId, documentType, documentName, file, setStatus },
});
export const uploadLeadDocumentSuccessed = (data) => ({
  type: type.UPLOAD_LEAD_DOCUMENT_SUCCESSED,
  payload: data,
});

export const fetchAdditionalDocumentRequests = (leadId) => ({
  type: type.FETCH_ADDITIONAL_DOCUMENT_REQUESTS,
  payload: { leadId },
});
export const fetchAdditionalDocumentRequestsSuccessed = (data) => ({
  type: type.FETCH_ADDITIONAL_DOCUMENT_REQUESTS_SUCCESSED,
  payload: data,
});

/* LEAD IMPORT */

export const importLead = (data, checkOnly, callback) => ({
  type: type.IMPORT_LEAD,
  payload: { data, checkOnly, callback },
});
export const importLeadSuccessed = (data) => ({
  type: type.IMPORT_LEAD_SUCCESSED,
  payload: data,
});
export const importLeadFailed = (error) => ({
  type: type.IMPORT_LEAD_FAILED,
  payload: error,
});

export const fetchLeadImportHistory = (pagination) => ({
  type: type.FETCH_LEAD_IMPORT_HISTORY,
  payload: { pagination },
});
export const fetchLeadImportHistorySuccessed = (data) => ({
  type: type.FETCH_LEAD_IMPORT_HISTORY_SUCCESSED,
  payload: data,
});
export const fetchLeadImportHistoryFailed = (error) => ({
  type: type.FETCH_LEAD_IMPORT_HISTORY_FAILED,
  payload: error,
});

export const fetchLeadImportHistoryItems = (code, pagination, filter) => ({
  type: type.FETCH_LEAD_IMPORT_HISTORY_ITEMS,
  payload: { code, pagination, filter },
});
export const fetchLeadImportHistoryItemsSuccessed = (data) => ({
  type: type.FETCH_LEAD_IMPORT_HISTORY_ITEMS_SUCCESSED,
  payload: data,
});
export const fetchLeadImportHistoryItemsFailed = (error) => ({
  type: type.FETCH_LEAD_IMPORT_HISTORY_ITEMS_FAILED,
  payload: error,
});

export const fetchLeadReportCount = (leadType, groupId) => ({
  type: type.FETCH_LEAD_REPORT_COUNT,
  payload: { leadType, groupId },
});
export const fetchLeadReportCountSuccessed = (data) => ({
  type: type.FETCH_LEAD_REPORT_COUNT_SUCCESSED,
  payload: data,
});
export const fetchLeadReportCountFailed = (error) => ({
  type: type.FETCH_LEAD_REPORT_COUNT_FAILED,
  payload: error,
});

export const fetchAllProduct = () => ({
  type: type.FETCH_ALL_PRODUCT,
  payload: {},
});
export const fetchAllProductSuccessed = (data) => ({
  type: type.FETCH_ALL_PRODUCT_SUCCESSED,
  payload: data,
});
export const fetchAllProductFailed = (error) => ({
  type: type.FETCH_ALL_PRODUCT_FAILED,
  payload: error,
});

export const fetchMission = () => ({
  type: type.FETCH_MISSION,
});
export const fetchMissionSuccessed = (data) => ({
  type: type.FETCH_MISSION_SUCCESSED,
  payload: data,
});
export const fetchMissionFailed = (error) => ({
  type: type.FETCH_MISSION_FAILED,
  payload: error,
});

export const updateAdditionalDocument = (data) => ({
  type: type.UPDATE_ADDITIONAL_DOCUMENT_REQUESTS,
  payload: data,
});
export const updateAdditionalDocumentSuccessed = (data) => ({
  type: type.UPDATE_ADDITIONAL_DOCUMENT_REQUESTS_SUCCESSED,
  payload: data,
});
export const updateAdditionalDocumentFailed = (data) => ({
  type: type.UPDATE_ADDITIONAL_DOCUMENT_REQUESTS_FAILED,
  payload: data,
});

export const fetchRule = (data) => ({
  type: type.FETCH_RULE,
  payload: data,
});
export const fetchRuleSuccessed = (data) => ({
  type: type.FETCH_RULE_SUCCESSED,
  payload: data,
});
export const fetchRuleFailed = (data) => ({
  type: type.FETCH_RULE_FAILED,
  payload: data,
});

export const updateRule = (data, cb) => ({
  type: type.UPDATE_RULE,
  payload: { data, cb },
});
export const updateRuleSuccessed = (data) => ({
  type: type.UPDATE_RULE_SUCCESSED,
  payload: data,
});
export const updateRuleFailed = (data) => ({
  type: type.UPDATE_RULE_FAILED,
  payload: data,
});

export const createRule = (data, cb) => ({
  type: type.CREATE_RULE,
  payload: { data, cb },
});
export const createRuleSuccessed = (data) => ({
  type: type.CREATE_RULE_SUCCESSED,
  payload: data,
});
export const createRuleFailed = (data) => ({
  type: type.CREATE_RULE_FAILED,
  payload: data,
});

export const autoAssignedLead = (data) => ({
  type: type.UPDATE_AUTO_ASSIGN_UNALLOCATED,
  payload: data,
});
export const autoAssignedLeadSuccessed = (data) => ({
  type: type.UPDATE_AUTO_ASSIGN_UNALLOCATED_SUCCESSED,
  payload: data,
});
export const autoAssignedLeadFailed = (data) => ({
  type: type.UPDATE_AUTO_ASSIGN_UNALLOCATED_FAILED,
  payload: data,
});

export const autoAssignedLeadFilter = (data) => ({
  type: type.UPDATE_AUTO_ASSIGN_UNALLOCATED_FILTER,
  payload: data,
});
export const autoAssignedLeadFilterSuccessed = (data) => ({
  type: type.UPDATE_AUTO_ASSIGN_UNALLOCATED_FILTER_SUCCESSED,
  payload: data,
});
export const autoAssignedLeadFilterFailed = (data) => ({
  type: type.UPDATE_AUTO_ASSIGN_UNALLOCATED_FILTER_FAILED,
  payload: data,
});

export const fetchRecallResult = (filter, pagination, loading = () => {}) => ({
  type: type.FETCH_RECALL_RESULT,
  payload: { filter, pagination, loading },
});

export const fetchRecallResultSuccessed = (data) => ({
  type: type.FETCH_RECALL_RESULT_SUCCESSED,
  payload: data,
});

export const fetchRecallResultFailed = (error) => ({
  type: type.FETCH_RECALL_RESULT_FAILED,
  payload: error,
});

export const fetchRecallList = (filter, pagination, loading = () => {}) => ({
  type: type.FETCH_RECALL_LIST,
  payload: { filter, pagination, loading },
});
export const fetchRecallListSuccessed = (data) => ({
  type: type.FETCH_RECALL_LIST_SUCCESSED,
  payload: data,
});
export const fetchRecallListFailed = (error) => ({
  type: type.FETCH_RECALL_LIST_FAILED,
  payload: error,
});

export const fetchAllRuleActive = () => ({
  type: type.FETCH_RULE_ACTIVE,
  payload: {},
});
export const fetchAllRuleActiveSuccessed = (data) => ({
  type: type.FETCH_RULE_ACTIVE_SUCCESSED,
  payload: data,
});
export const fetchAllRuleActiveFailed = (error) => ({
  type: type.FETCH_RULE_ACTIVE_FAILED,
  payload: error,
});

export const assignLeadAuto = (ruleId, leads) => ({
  type: type.FETCH_ASSIGN_LEAD_AUTO,
  payload: { ruleId, leads },
});
export const assignLeadAutoSuccessed = (data) => ({
  type: type.FETCH_ASSIGN_LEAD_AUTO_SUCCESSED,
  payload: data,
});
export const assignLeadAutoFailed = (error) => ({
  type: type.FETCH_ASSIGN_LEAD_AUTO_FAILED,
  payload: error,
});

export const assignLeadManual = (username, leads) => ({
  type: type.FETCH_ASSIGN_LEAD_MANUAL,
  payload: { username, leads },
});
export const assignLeadManualSuccessed = (data) => ({
  type: type.FETCH_ASSIGN_LEAD_MANUAL_SUCCESSED,
  payload: data,
});
export const assignLeadManualFailed = (error) => ({
  type: type.FETCH_ASSIGN_LEAD_MANUAL_FAILED,
  payload: error,
});

export const activeRule = (data) => ({
  type: type.ACTIVE_RULE,
  payload: data,
});
export const activeRuleSuccessed = (data) => ({
  type: type.ACTIVE_RULE_SUCCESSED,
  payload: data,
});
export const activeRuleFailed = (data) => ({
  type: type.ACTIVE_RULE_FAILED,
  payload: data,
});

export const deActiveRule = (data) => ({
  type: type.DEACTIVE_RULE,
  payload: data,
});
export const deActiveRuleSuccessed = (data) => ({
  type: type.DEACTIVE_RULE_SUCCESSED,
  payload: data,
});
export const deActiveRuleFailed = (data) => ({
  type: type.DEACTIVE_RULE_FAILED,
  payload: data,
});

export const settingUpdateAutoAssign = (data) => ({
  type: type.SETTING_AUTO_ASSIGN,
  payload: data,
});
export const settingUpdateAutoAssignSuccessed = (data) => ({
  type: type.SETTING_AUTO_ASSIGN_SUCCESSED,
  payload: data,
});
export const settingUpdateAutoAssignFailed = (data) => ({
  type: type.SETTING_AUTO_ASSIGN_FAILED,
  payload: data,
});

export const deleteRule = (data) => ({
  type: type.DELETE_RULE,
  payload: data,
});
export const deleteRuleSuccessed = (data) => ({
  type: type.DELETE_RULE_SUCCESSED,
  payload: data,
});
export const deleteRuleFailed = (data) => ({
  type: type.DELETE_RULE_FAILED,
  payload: data,
});
export const fetchVariable = () => ({
  type: type.FETCH_VARIABLE,
  payload: {},
});
export const fetchVariableSuccessed = (data) => ({
  type: type.FETCH_VARIABLE_SUCCESSED,
  payload: data,
});
export const fetchVariableFailed = (error) => ({
  type: type.FETCH_VARIABLE_FAILED,
  payload: error,
});

export const SendEmailActivity = (leadId, target, title, content) => ({
  type: type.SEND_EMAIL_ACTIVITY,
  payload: { leadId, target, title, content },
});
export const SendEmailActivitySuccessed = (data) => ({
  type: type.SEND_EMAIL_ACTIVITY_SUCCESSED,
  payload: data,
});
export const SendEmailActivityFailed = (error) => ({
  type: type.SEND_EMAIL_ACTIVITY_FAILED,
  payload: error,
});

export const getNumberTargetKPI = (data) => ({
  type: type.GET_NUMBER_TARGET_KPI,
  payload: data,
});
export const getNumberTargetKPISuccessed = (data) => ({
  type: type.GET_NUMBER_TARGET_KPI_SUCCESSED,
  payload: data,
});
export const getNumberTargetKPIFailed = (error) => ({
  type: type.GET_NUMBER_TARGET_KPI_FAILED,
  payload: error,
});

export const getReportLead = (data) => ({
  type: type.REPORT_LEAD,
  payload: data,
});
export const getReportLeadSuccessed = (data) => ({
  type: type.REPORT_LEAD_SUCCESSED,
  payload: data,
});
export const getReportLeadFailed = (error) => ({
  type: type.REPORT_LEAD_FAILED,
  payload: error,
});

export const getReportLeadActivity = (data) => ({
  type: type.REPORT_LEAD_ACTIVITY,
  payload: data,
});
export const getReportLeadActivitySuccessed = (data) => ({
  type: type.REPORT_LEAD_ACTIVITY_SUCCESSED,
  payload: data,
});
export const getReportLeadActivityFailed = (error) => ({
  type: type.REPORT_LEAD_ACTIVITY_FAILED,
  payload: error,
});

export const getReportLeadMcas = (data) => ({
  type: type.REPORT_LEAD_MCAS,
  payload: data,
});
export const getReportLeadMcasSuccessed = (data) => ({
  type: type.REPORT_LEAD_MCAS_SUCCESSED,
  payload: data,
});
export const getReportLeadMcasFailed = (error) => ({
  type: type.REPORT_LEAD_MCAS_FAILED,
  payload: error,
});

export const getReportLeadDistributedAmount = (data) => ({
  type: type.REPORT_LEAD_DISTRIBUTED_AMOUNT,
  payload: data,
});
export const getReportLeadDistributedAmountSuccessed = (data) => ({
  type: type.REPORT_LEAD_DISTRIBUTED_AMOUNT_SUCCESSED,
  payload: data,
});
export const getReportLeadDistributedAmountFailed = (error) => ({
  type: type.REPORT_LEAD_DISTRIBUTED_AMOUNT_FAILED,
  payload: error,
});

export const getReportLeadDistributed = (data) => ({
  type: type.REPORT_LEAD_DISTRIBUTED_LEAD,
  payload: data,
});
export const getReportLeadDistributedSuccessed = (data) => ({
  type: type.REPORT_LEAD_DISTRIBUTED_LEAD_SUCCESSED,
  payload: data,
});
export const getReportLeadDistributedFailed = (error) => ({
  type: type.REPORT_LEAD_DISTRIBUTED_LEAD_FAILED,
  payload: error,
});

export const getReportLeadPartner = (data) => ({
  type: type.REPORT_LEAD_PARTNER,
  payload: data,
});
export const getReportLeadPartnerSuccessed = (data) => ({
  type: type.REPORT_LEAD_PARTNER_SUCCESSED,
  payload: data,
});
export const getReportLeadPartnerFailed = (error) => ({
  type: type.REPORT_LEAD_PARTNER_FAILED,
  payload: error,
});

export const getConversionFunnel = () => ({
  type: type.GET_CONVERSION_FUNNEL,
});
export const getConversionFunnelSuccessed = (data) => ({
  type: type.GET_CONVERSION_FUNNEL_SUCCESSED,
  payload: data,
});
export const getConversionFunnelFailed = (error) => ({
  type: type.GET_CONVERSION_FUNNEL_FAILED,
  payload: error,
});

export const getDashboardSummary = () => ({
  type: type.GET_DASHBOARD_SUMMARY,
});
export const getDashboardSummarySuccessed = (data) => ({
  type: type.GET_DASHBOARD_SUMMARY_SUCCESSED,
  payload: data,
});
export const getDashboardSummaryFailed = (error) => ({
  type: type.GET_DASHBOARD_SUMMARY_FAILED,
  payload: error,
});

export const getDashboardEmployeeSummary = (data) => ({
  type: type.GET_DASHBOARD_EMPLOYEE_SUMMARY,
  payload: data,
});
export const getDashboardEmployeeSummarySuccessed = (data) => ({
  type: type.GET_DASHBOARD_EMPLOYEE_SUMMARY_SUCCESSED,
  payload: data,
});
export const getDashboardEmployeeSummaryFailed = (error) => ({
  type: type.GET_DASHBOARD_EMPLOYEE_SUMMARY_FAILED,
  payload: error,
});

export const getDashboardPosSummary = (data) => ({
  type: type.GET_DASHBOARD_POS_SUMMARY,
  payload: data,
});
export const getDashboardPosSummarySuccessed = (data) => ({
  type: type.GET_DASHBOARD_POS_SUMMARY_SUCCESSED,
  payload: data,
});
export const getDashboardPosSummaryFailed = (error) => ({
  type: type.GET_DASHBOARD_POS_SUMMARY_FAILED,
  payload: error,
});

export const getDashboardDisbursement = (data) => ({
  type: type.GET_DASHBOARD_DISBURSEMENT,
  payload: data,
});
export const getDashboardDisbursementSuccessed = (data) => ({
  type: type.GET_DASHBOARD_DISBURSEMENT_SUCCESSED,
  payload: data,
});
export const getDashboardDisbursementFailed = (error) => ({
  type: type.GET_DASHBOARD_DISBURSEMENT_FAILED,
  payload: error,
});

export const getDashboardDisbursementSale = (data) => ({
  type: type.GET_DASHBOARD_DISBURSEMENT_SALES,
  payload: data,
});
export const getDashboardDisbursementSaleSuccessed = (data) => ({
  type: type.GET_DASHBOARD_DISBURSEMENT_SALES_SUCCESSED,
  payload: data,
});
export const getDashboardDisbursementSaleFailed = (error) => ({
  type: type.GET_DASHBOARD_DISBURSEMENT_SALES_FAILED,
  payload: error,
});

export const getDashboardDisbursementSaleMonthSum = (data) => ({
  type: type.GET_DASHBOARD_DISBURSEMENT_SALES_MONTH_SUM,
  payload: data,
});
export const getDashboardDisbursementSaleMonthSumSuccessed = (data) => ({
  type: type.GET_DASHBOARD_DISBURSEMENT_SALES_MONTH_SUM_SUCCESSED,
  payload: data,
});
export const getDashboardDisbursementSaleMonthSumFailed = (error) => ({
  type: type.GET_DASHBOARD_DISBURSEMENT_SALES_MONTH_SUM_FAILED,
  payload: error,
});

export const getDashboardDisbursementProduct = (data) => ({
  type: type.GET_DASHBOARD_DISBURSEMENT_PRODUCT,
  payload: data,
});
export const getDashboardDisbursementProductSuccessed = (data) => ({
  type: type.GET_DASHBOARD_DISBURSEMENT_PRODUCT_SUCCESSED,
  payload: data,
});
export const getDashboardDisbursementProductFailed = (error) => ({
  type: type.GET_DASHBOARD_DISBURSEMENT_PRODUCT_SUCCESSED,
  payload: error,
});

export const getDashboardLeadConversion = (data) => ({
  type: type.GET_DASHBOARD_LEAD_CONVERSION,
  payload: data,
});
export const getDashboardLeadConversionSuccessed = (data) => ({
  type: type.GET_DASHBOARD_LEAD_CONVERSION_SUCCESSED,
  payload: data,
});
export const getDashboardLeadConversionFailed = (error) => ({
  type: type.GET_DASHBOARD_LEAD_CONVERSION_FAILED,
  payload: error,
});

export const getDashboardDisbursementMonthCount = (data) => ({
  type: type.GET_DASHBOARD_DISBURSEMENT_MOTH_COUNT,
  payload: data,
});
export const getDashboardDisbursementMonthCountSuccessed = (data) => ({
  type: type.GET_DASHBOARD_DISBURSEMENT_MONTH_COUNT_SUCCESSED,
  payload: data,
});
export const getDashboardDisbursementMonthCountFailed = (error) => ({
  type: type.GET_DASHBOARD_DISBURSEMENT_MONTH_COUNT_FAILED,
  payload: error,
});

export const getDashboardDisbursementCount = (data) => ({
  type: type.GET_DASHBOARD_DISBURSEMENT_COUNT,
  payload: data,
});
export const getDashboardDisbursementCountSuccessed = (data) => ({
  type: type.GET_DASHBOARD_DISBURSEMENT_COUNT_SUCCESSED,
  payload: data,
});
export const getDashboardDisbursementCountFailed = (error) => ({
  type: type.GET_DASHBOARD_DISBURSEMENT_COUNT_FAILED,
  payload: error,
});

// TT
export const getDashboardEmployeeRating = (data) => ({
  type: type.GET_DASHBOARD_EMPLOYEE_RATING,
  payload: data,
});
export const getDashboardEmployeeRatingSuccessed = (data) => ({
  type: type.GET_DASHBOARD_EMPLOYEE_RATING_SUCCESSED,
  payload: data,
});
export const getDashboardEmployeeRatingFailed = (error) => ({
  type: type.GET_DASHBOARD_EMPLOYEE_RATING_FAILED,
  payload: error,
});

// TT
export const getDashboardPosRating = (data) => ({
  type: type.GET_DASHBOARD_POS_RATING,
  payload: data,
});
export const getDashboardPosRatingSuccessed = (data) => ({
  type: type.GET_DASHBOARD_POS_RATING_SUCCESSED,
  payload: data,
});
export const getDashboardPosRatingFailed = (error) => ({
  type: type.GET_DASHBOARD_POS_RATING_FAILED,
  payload: error,
});

export const fetchLeadFieldNote = (leadId) => ({
  type: type.FETCH_LEAD_FIELD_NOTE,
  payload: leadId,
});
export const fetchLeadFieldNoteSuccessed = (data) => ({
  type: type.FETCH_LEAD_FIELD_NOTE_SUCCESSED,
  payload: data,
});
export const fetchLeadFieldNoteFailed = (error) => ({
  type: type.FETCH_LEAD_FIELD_NOTE_FAILED,
  payload: error,
});

export const updateLeadFieldNote = (leadId, data, callback) => ({
  type: type.UPDATE_LEAD_FIELD_NOTE,
  payload: { leadId, data, callback },
});
export const updateLeadFieldNoteSuccessed = (data) => ({
  type: type.UPDATE_LEAD_FIELD_NOTE_SUCCESSED,
  payload: data,
});
export const updateLeadFieldNoteFailed = (error) => ({
  type: type.UPDATE_LEAD_FIELD_NOTE_FAILED,
  payload: error,
});

export const fetchAllInsurance = () => ({
  type: type.FETCH_ALL_INSURANCE,
});
export const fetchAllInsuranceSuccessed = (data) => ({
  type: type.FETCH_ALL_INSURANCE_SUCCESSED,
  payload: data,
});
export const fetchAllInsuranceFailed = (error) => ({
  type: type.FETCH_ALL_INSURANCE_FAILED,
  payload: error,
});

// ********************************************************************************
// CAR REGISTER
export const fetchCarRegister = (image, loading = () => {}) => ({
  type: type.FETCH_CAR_REGISTER,
  payload: { image, loading },
});
export const fetchCarRegisterSuccessed = (data) => ({
  type: type.FETCH_CAR_REGISTER_SUCCESSED,
  payload: data,
});
export const fetchCarRegisterFailed = (error) => ({
  type: type.FETCH_CAR_REGISTER_FAILED,
  payload: error,
});

// CAR DAMAGE
export const fetchCarDamage = (imageCarDamage, loading = () => {}) => ({
  type: type.FETCH_CAR_DAMAGE,
  payload: { imageCarDamage, loading },
});
export const fetchCarDamageSuccessed = (data) => ({
  type: type.FETCH_CAR_DAMAGE_SUCCESSED,
  payload: data,
});
export const fetchCarDamageFailed = (error) => ({
  type: type.FETCH_CAR_DAMAGE_FAILED,
  payload: error,
});

export const fetchCarFront = (imageFront, loading = () => {}) => ({
  type: type.FETCH_CAR_FRONT,
  payload: { imageFront, loading },
});
export const fetchCarFrontSuccessed = (data) => ({
  type: type.FETCH_CAR_FRONT_SUCCESSED,
  payload: data,
});
export const fetchCarFrontFailed = (error) => ({
  type: type.FETCH_CAR_FRONT_FAILED,
  payload: error,
});

export const fetchCarBack = (imageBack, loading = () => {}) => ({
  type: type.FETCH_CAR_BACK,
  payload: { imageBack, loading },
});
export const fetchCarBackSuccessed = (data) => ({
  type: type.FETCH_CAR_BACK_SUCCESSED,
  payload: data,
});
export const fetchCarBackFailed = (error) => ({
  type: type.FETCH_CAR_BACK_FAILED,
  payload: error,
});

export const fetchCarLeft = (imageLeft, loading = () => {}) => ({
  type: type.FETCH_CAR_LEFT,
  payload: { imageLeft, loading },
});
export const fetchCarLeftSuccessed = (data) => ({
  type: type.FETCH_CAR_LEFT_SUCCESSED,
  payload: data,
});
export const fetchCarLeftFailed = (error) => ({
  type: type.FETCH_CAR_LEFT_FAILED,
  payload: error,
});

export const fetchCarRight = (imageRight, loading = () => {}) => ({
  type: type.FETCH_CAR_RIGHT,
  payload: { imageRight, loading },
});
export const fetchCarRightSuccessed = (data) => ({
  type: type.FETCH_CAR_RIGHT_SUCCESSED,
  payload: data,
});
export const fetchCarRightFailed = (error) => ({
  type: type.FETCH_CAR_RIGHT_FAILED,
  payload: error,
});
