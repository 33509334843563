export const isObject = (a) => a instanceof Object;

export const isString = (a) => typeof a === 'string';

export const isFunction = (val) => typeof val === 'function';

export const get = (obj, path, defValue) => {
  // If path is not defined or it has false value
  if (!path) return undefined;
  // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
  // Find value
  const result = pathArray.reduce((prevObj, key) => prevObj && prevObj[key], obj);
  // If found value is undefined return default value; otherwise return the value
  return result === undefined ? defValue : result;
};

export const uniqBy = (arr, iteratee) => {
  if (typeof iteratee === 'string') {
    const prop = iteratee;
    iteratee = (item) => item[prop];
  }

  return arr.filter((x, i, self) => i === self.findIndex((y) => iteratee(x) === iteratee(y)));
};

export const unionBy = (arr, ...args) => {
  let iteratee = args.pop();
  if (typeof iteratee === 'string') {
    const prop = iteratee;
    iteratee = (item) => item[prop];
  }

  return arr.concat(...args).filter((x, i, self) => i === self.findIndex((y) => iteratee(x) === iteratee(y)));
};

export const uniqueId = (
  (counter) =>
  (str = '') =>
    `${str}${++counter}`
)(0);

export const uniq = (array) => [...new Set(array)];

export const mapValues = (obj, mapper) =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: mapper(value),
    }),
    {}
  );

export const clone = (data) => structuredClone(data);
// export const clone = (data) => JSON.parse(JSON.stringify(data));
