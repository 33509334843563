import { activityCodeApi } from './activity-code/apiSlice';
import { customFieldApi } from './custom-field/apiSlice';
import { documentApi } from './document/apiSlice';
import { leadApi } from './lead/apiSlice';
import { productApi } from './product/apiSlice';

const appMiddleware = [
  activityCodeApi.middleware,
  customFieldApi.middleware,
  documentApi.middleware,
  leadApi.middleware,
  productApi.middleware,
];

export default appMiddleware;
