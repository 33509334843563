import { fetchJSON, getAccessToken } from 'helpers/api';
import { ENP } from 'constants/api';

export const fetchAllKpi = () => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/kpi/info/get-all`, options);
};

export const fetchAllMonthOfKpi = (id) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/kpi/info/${id}/get-all-number`, options);
};

export const updateKpi = (id, data) => {
  const options = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.LEAD_API}/kpi/info/${id}/update`, options);
};

export const getDetailApi = (data) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.LEAD_API}/kpi/number/filter`, options);
};

export const createKpiNumber = (data) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.LEAD_API}/kpi/number/update`, options);
};

export const getLogNumber = (data) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.LEAD_API}/kpi/log/filter`, options);
};

export const getItemByMonth = ({ id, month }) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/kpi/number/kpi/${id}/month/${month}/product`, options);
};

export const groupLeaders = (username) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.SYS_ACC_API}/admin/group/level/leader/${username}`, options);
};
