// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
/* eslint-disable no-unused-vars */
import { handleLoading } from 'redux/layout/actions';
import { EXPIRE_TIME_KEY, fetchJSON } from 'helpers/api';
import { hasRole, setLoggedIn, setLoggedInUser } from 'helpers/authUtils';
import toast from 'react-hot-toast';
import { ACCOUNT_LOGIN_WEB, ACCOUNT_SVC } from 'constants/roles';
import * as api from './api';
import {
  changeUserPasswordFailed,
  changeUserPasswordSuccess,
  forgetPasswordFailed,
  forgetPasswordSuccess,
  getStringeeTokenSuccessed,
  loginUserFailed,
  loginUserSuccess,
  refreshTokenFailed,
  refreshTokenSuccessed,
  registerUserFailed,
  registerUserSuccess,
  removeUserInfo,
  setWebPhone,
  updateProfileFailed,
  updateProfileSuccess,
} from './actions';
import * as type from './constants';

const autoLogout = (expiredIn) => {
  const logoutTime = new Date();
  logoutTime.setSeconds(logoutTime.getSeconds() + expiredIn);
  localStorage.setItem(EXPIRE_TIME_KEY, logoutTime.getTime());
};

function* login({ payload }) {
  // yield put(handleLoading(true));
  const { username, password, options } = payload;
  const { loading, onSuccess } = options;

  loading && loading(true);
  try {
    const loginResponse = yield call(api.login, { username, password });
    autoLogout(loginResponse.expires_in);
    setLoggedIn(loginResponse);

    if (hasRole(ACCOUNT_SVC, ACCOUNT_LOGIN_WEB)) {
      const userResponse = yield call(api.getLoggedInUser, loginResponse.access_token);
      setLoggedInUser(userResponse);

      const response = {
        user: userResponse,
        loggedIn: loginResponse,
      };
      yield put(loginUserSuccess(response));
      onSuccess && onSuccess(response);

      toast.success('Đăng nhập thành công');
    } else {
      toast.error('Bạn không có quyền đăng nhập trên web');
    }
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(loginUserFailed(message));
    setLoggedInUser(null);
    setLoggedIn(null);
    toast.error('Đăng nhập thất bại');
  }
  loading && loading(false);
  // yield put(handleLoading(false));
}

function* refreshToken({ payload }) {
  try {
    const response = yield call(api.refreshToken, payload);
    setLoggedIn(response);

    yield put(refreshTokenSuccessed(response));
  } catch (error) {
    yield put(refreshTokenFailed(error));
  }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout() {
  try {
    setLoggedInUser(null);
    setLoggedIn(null);

    yield put(removeUserInfo());
    yield put(setWebPhone(null));
  } catch (error) {
    console.error(error);
  }
}

/**
 * Register the user
 */
function* register({ payload: { fullname, email, password } }) {
  const options = {
    body: JSON.stringify({ fullname, email, password }),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };

  yield put(handleLoading(true));

  try {
    const response = yield call(fetchJSON, '/users/register', options);
    yield put(registerUserSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(registerUserFailed(message));
  }

  yield put(handleLoading(false));
}

/**
 * Update profile
 */
function* updateUser(action) {
  const { profile, avatar } = action.payload;
  yield put(handleLoading(true));

  try {
    const response = yield call(api.updateProfile, profile);

    if (avatar) {
      yield call(api.updateAvatar, avatar);
    }

    toast.success('Cập nhật profile thành công');
    yield put(updateProfileSuccess(response));
  } catch (error) {
    yield put(updateProfileFailed(error));
  }
  yield put(handleLoading(false));
}

/**
 * forget password
 */
function* forgetPassword({ payload: { username } }) {
  const options = {
    body: JSON.stringify({ username }),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };

  yield put(handleLoading(true));

  try {
    const response = yield call(fetchJSON, '/users/password-reset', options);
    yield put(forgetPasswordSuccess(response.message));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(forgetPasswordFailed(message));
  }

  yield put(handleLoading(false));
}

/**
 * change password
 */
function* changePassword(action) {
  const { payload, navigate } = action.payload;
  yield put(handleLoading(true));

  try {
    const response = yield call(api.changePassword, payload);
    yield put(changeUserPasswordSuccess(response.message));
    yield call(() => {
      navigate('/account/logout');
    });
  } catch (error) {
    yield put(changeUserPasswordFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchStringeeToken(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.getStringeeToken, action.payload);

    yield put(getStringeeTokenSuccessed(response.access_token));
  } catch (error) {
    yield put(getStringeeTokenSuccessed(null));
  }

  yield put(handleLoading(false));
}

export function* watchLoginUser() {
  yield takeEvery(type.LOGIN_USER, login);
}

export function* watchLogoutUser() {
  yield takeEvery(type.LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
  yield takeEvery(type.REGISTER_USER, register);
}

export function* watchUpdateUser() {
  yield takeEvery(type.UPDATE_USER, updateUser);
}

export function* watchForgetPassword() {
  yield takeEvery(type.FORGET_PASSWORD, forgetPassword);
}

export function* watchRefreshToken() {
  yield takeEvery(type.REFRESH_TOKEN, refreshToken);
}

export function* watchChangePassword() {
  yield takeEvery(type.CHANGE_PASSWORD, changePassword);
}

export function* watchStringeeToken() {
  yield takeEvery(type.GET_STRINGEE_TOKEN, fetchStringeeToken);
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchUpdateUser),
    fork(watchForgetPassword),
    fork(watchChangePassword),
    fork(watchRefreshToken),
    fork(watchStringeeToken),
  ]);
}

export default authSaga;
