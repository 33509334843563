import * as type from './constants';

/* Accounts actions */
export const fetchCampaigns = (pagination, loading = () => {}) => ({
  type: type.FETCH_CAMPAIGNS,
  payload: { pagination, loading },
});
export const fetchCampaignsSuccessed = (data) => ({
  type: type.FETCH_CAMPAIGNS_SUCCESSED,
  payload: data,
});
export const fetchCampaignsFailed = (error) => ({
  type: type.FETCH_CAMPAIGNS_FAILED,
  payload: error,
});
export const clearCampaigns = () => ({ type: type.CLEAR_CAMPAIGNS });

export const fetchCampaignsByFilter = (filter, pagination, loading = () => {}) => ({
  type: type.FETCH_CAMPAIGNS_BY_FILTER,
  payload: { filter, pagination, loading },
});

export const fetchCampaignDetail = (code) => ({ type: type.FETCH_CAMPAIGN_DETAIL, payload: code });
export const fetchCampaignDetailSuccessed = (data) => ({
  type: type.FETCH_CAMPAIGN_DETAIL_SUCCESSED,
  payload: data,
});
export const fetchCampaignDetailFailed = (error) => ({
  type: type.FETCH_CAMPAIGN_DETAIL_FAILED,
  payload: error,
});

export const createCampaign = (data, setState = () => {}) => ({
  type: type.CREATE_CAMPAIGN,
  payload: { data, setState },
});
export const createCampaignSuccessed = (data) => ({
  type: type.CREATE_CAMPAIGN_SUCCESSED,
  payload: data,
});
export const createCampaignFailed = (error) => ({
  type: type.CREATE_CAMPAIGN_FAILED,
  payload: error,
});

export const updateCampaign = (campaignCode, data, setState = () => {}) => ({
  type: type.UPDATE_CAMPAIGN,
  payload: { campaignCode, data, setState },
});
export const updateCampaignSuccessed = (data) => ({
  type: type.UPDATE_CAMPAIGN_SUCCESSED,
  payload: data,
});
export const updateCampaignFailed = (error) => ({
  type: type.UPDATE_CAMPAIGN_FAILED,
  payload: error,
});

export const deleteCampaign = (campaignCode, setState = () => {}, loading = () => {}) => ({
  type: type.DELETE_CAMPAIGN,
  payload: { campaignCode, setState, loading },
});
export const deleteCampaignSuccessed = (data) => ({
  type: type.DELETE_CAMPAIGN_SUCCESSED,
  payload: data,
});
export const deleteCampaignFailed = (error) => ({
  type: type.DELETE_CAMPAIGN_FAILED,
  payload: error,
});

export const fetchCampaignCode = (cate, campaignId) => ({
  type: type.FETCH_CAMPAIGN_CODE,
  payload: { cate, campaignId },
});
export const fetchCampaignCodeSuccessed = (data) => ({
  type: type.FETCH_CAMPAIGN_CODE_SUCCESSED,
  payload: data,
});
export const fetchCampaignCodeFailed = (error) => ({
  type: type.FETCH_CAMPAIGN_CODE_FAILED,
  payload: error,
});

export const createCampaignCode = (data) => ({ type: type.CREATE_CAMPAIGN_CODE, payload: data });
export const createCampaignCodeSuccessed = (data) => ({
  type: type.CREATE_CAMPAIGN_CODE_SUCCESSED,
  payload: data,
});
export const createCampaignCodeFailed = (error) => ({
  type: type.CREATE_CAMPAIGN_CODE_FAILED,
  payload: error,
});

export const updateCampaignCode = (codeId, data) => ({
  type: type.UPDATE_CAMPAIGN_CODE,
  payload: { codeId, data },
});
export const updateCampaignCodeSuccessed = (data) => ({
  type: type.UPDATE_CAMPAIGN_CODE_SUCCESSED,
  payload: data,
});
export const updateCampaignCodeFailed = (error) => ({
  type: type.UPDATE_CAMPAIGN_CODE_FAILED,
  payload: error,
});

export const fetchCampaignGroups = (cate, campaignId) => ({
  type: type.FETCH_CAMPAIGN_GROUPS,
  payload: { cate, campaignId },
});
export const fetchCampaignGroupsSuccessed = (data) => ({
  type: type.FETCH_CAMPAIGN_GROUPS_SUCCESSED,
  payload: data,
});
export const fetchCampaignGroupsFailed = (error) => ({
  type: type.FETCH_CAMPAIGN_GROUPS_FAILED,
  payload: error,
});

export const fetchCampaignGroupDetail = (groupId) => ({
  type: type.FETCH_CAMPAIGN_GROUP_DETAIL,
  payload: groupId,
});
export const fetchCampaignGroupDetailSuccessed = (data) => ({
  type: type.FETCH_CAMPAIGN_GROUP_DETAIL_SUCCESSED,
  payload: data,
});
export const fetchCampaignGroupDetailFailed = (error) => ({
  type: type.FETCH_CAMPAIGN_GROUP_DETAIL_FAILED,
  payload: error,
});

export const createCampaignGroup = (data) => ({ type: type.CREATE_CAMPAIGN_GROUP, payload: data });
export const createCampaignGroupSuccessed = (data) => ({
  type: type.CREATE_CAMPAIGN_GROUP_SUCCESSED,
  payload: data,
});
export const createCampaignGroupFailed = (error) => ({
  type: type.CREATE_CAMPAIGN_GROUP_FAILED,
  payload: error,
});

export const updateCampaignGroup = (groupId, data) => ({
  type: type.UPDATE_CAMPAIGN_GROUP,
  payload: { groupId, data },
});
export const updateCampaignGroupSuccessed = (data) => ({
  type: type.UPDATE_CAMPAIGN_GROUP_SUCCESSED,
  payload: data,
});
export const updateCampaignGroupFailed = (error) => ({
  type: type.UPDATE_CAMPAIGN_GROUP_FAILED,
  payload: error,
});

export const setOpenCreateCampaign = (status) => ({
  type: type.SET_OPEN_CREATE_CAMPAIGN,
  payload: status,
});

// Campaign partner
export const fetchCampaignPartners = (campaignCode) => ({
  type: type.FETCH_CAMPAIGN_PARTNERS,
  payload: campaignCode,
});
export const fetchCampaignPartnersSuccessed = (data) => ({
  type: type.FETCH_CAMPAIGN_PARTNERS_SUCCESSED,
  payload: data,
});
export const fetchCampaignPartnersFailed = (error) => ({
  type: type.FETCH_CAMPAIGN_PARTNERS_FAILED,
  payload: error,
});

export const createCampaignPartner = (data, setState = () => {}) => ({
  type: type.CREATE_CAMPAIGN_PARTNER,
  payload: { data, setState },
});
export const createCampaignPartnerSuccessed = (data) => ({
  type: type.CREATE_CAMPAIGN_PARTNER_SUCCESSED,
  payload: data,
});
export const createCampaignPartnerFailed = (error) => ({
  type: type.CREATE_CAMPAIGN_PARTNER_FAILED,
  payload: error,
});

export const updateCampaignPartner = (data, setState = () => {}) => ({
  type: type.UPDATE_CAMPAIGN_PARTNER,
  payload: { data, setState },
});
export const updateCampaignPartnerSuccessed = (data) => ({
  type: type.UPDATE_CAMPAIGN_PARTNER_SUCCESSED,
  payload: data,
});
export const updateCampaignPartnerFailed = (error) => ({
  type: type.UPDATE_CAMPAIGN_PARTNER_FAILED,
  payload: error,
});

export const deleteCampaignPartner = (campaignCode, productCode, setState = () => {}) => ({
  type: type.DELETE_CAMPAIGN_PARTNER,
  payload: { campaignCode, productCode, setState },
});
export const deleteCampaignPartnerSuccessed = (data) => ({
  type: type.DELETE_CAMPAIGN_PARTNER_SUCCESSED,
  payload: data,
});
export const deleteCampaignPartnerFailed = (error) => ({
  type: type.DELETE_CAMPAIGN_PARTNER_FAILED,
  payload: error,
});

export const updateCampaignStatus = (campaignCode, newStatus, setState = () => {}, loading = () => {}) => ({
  type: type.UPDATE_CAMPAIGN_STATUS,
  payload: { campaignCode, newStatus, setState, loading },
});

export const storeCampaignFilterConfig = (data) => ({
  type: type.STORE_CAMPAIGN_FILTER_CONFIG,
  payload: data,
});

export const reportTopPartnerCountSuccessed = (data) => ({
  type: type.REPORT_TOP_PARTNER_COUNT_SUCCESSED,
  payload: data,
});
export const reportTopPartnerCountFailed = (error) => ({
  type: type.REPORT_TOP_PARTNER_COUNT_FAILED,
  payload: error,
});

export const leadStatusCountSuccessed = (data) => ({
  type: type.LEAD_STATUS_COUNT_SUCCESSED,
  payload: data,
});
export const leadStatusCountFailed = (error) => ({
  type: type.LEAD_STATUS_COUNT_FAILED,
  payload: error,
});

export const dayCountCountSuccessed = (data) => ({
  type: type.DAY_COUNT_COUNT_SUCCESSED,
  payload: data,
});
export const dayCountCountFailed = (error) => ({
  type: type.DAY_COUNT_COUNT_FAILED,
  payload: error,
});

export const disbursementSuccessed = (data) => ({
  type: type.DISBURSEMENT_SUCCESSED,
  payload: data,
});
export const disbursementFailed = (error) => ({ type: type.DISBURSEMENT_FAILED, payload: error });

export const leadValidCountSuccessed = (data) => ({
  type: type.LEAD_VALID_COUNT_SUCCESSED,
  payload: data,
});
export const leadValidCountFailed = (error) => ({
  type: type.LEAD_VALID_COUNT_FAILED,
  payload: error,
});
