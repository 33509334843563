import './init';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
// import { I18nextProvider } from 'react-i18next';
import { store } from './redux/store';
import App from './App';
// import i18n from './i18n';

// import reportWebVitals from './reportWebVitals';
// import { registerFcm } from './firebase/fcmRegistration';

import 'assets/scss/theme.scss';

import './index.css';

// const isProduction = import.meta.env.NODE_ENV === 'production';

// if (isProduction) {
//   // eslint-disable-next-line no-console
//   console.log = () => {};
//   console.warn = () => {};
//   console.error = () => {};
// }

// const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
// const currentLanguage = getLanguage();

// const theme = createTheme(
//   merge({}, themeColors.nxLight, themeOptions) as IThemeOptions
//   // currentLanguage === "vi" ? viVN : enUS
// );

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    {/* <I18nextProvider i18n={i18n}> */}
    <Provider store={store}>
      <App />
    </Provider>
    {/* </I18nextProvider> */}
  </StrictMode>
);

// Legacy render
// ReactDOM.render(
//     <I18nextProvider i18n={i18n}>
//         <Provider store={store}>
//             <ThemeProvider theme={theme}>
//                 <ToastContainer limit={3} autoClose={8000} position="bottom-right" />
//                 <App />
//             </ThemeProvider>
//         </Provider>
//     </I18nextProvider>,
//     document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);

// Background FCM notifications
// registerFcm();
