import { fetchJSON, getAccessToken } from 'helpers/api';
import { ENP } from 'constants/api';
import { Pagination } from 'redux/types';
import { CreateProductGroup, Filter, Insurance, Product, ProductUpdate } from './types';

// **************************************************************************************
// PRODUCT API

export const fetchProductDocuments = (productCode: string) => {
  const option = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.PRODUCT_API}/product/${productCode}/document`, option);
};

export const uploadProductDocument = (productCode: string, file: any, params: Pagination) => {
  const formData = new FormData();
  formData.append('file', file);

  const options = {
    body: formData,
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.PRODUCT_API}/product/${productCode}/document`, options, params);
};

export const updateProduct = (productCode: string, data: Product) => {
  const options = {
    body: JSON.stringify(data),
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };
  return fetchJSON(`${ENP.PRODUCT_API}/product/${productCode}`, options);
};

export const createProductGroup = (data: CreateProductGroup) => {
  const options = {
    body: JSON.stringify(data),
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.PRODUCT_API}/product/group`, options);
};

export const updateProductGroup = (id: string, data: CreateProductGroup) => {
  const options = {
    body: JSON.stringify(data),
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };
  return fetchJSON(`${ENP.PRODUCT_API}/product/group/${id}`, options);
};

export const updateStatusProduct = (productCode: string, status: string) => {
  const options = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };
  return fetchJSON(`${ENP.PRODUCT_API}/product/${productCode}/${status}`, options);
};

export const deleteProduct = (productCode: string) => {
  const options = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.PRODUCT_API}/product/${productCode}`, options);
};

export const deleteProductGroup = (id: string) => {
  const options = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.PRODUCT_API}/product/group/${id}`, options);
};

// **************************************************************************************
// INSURANCE API

export const createInsurance = (data: Insurance) => {
  const options = {
    body: JSON.stringify(data),
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.PRODUCT_API}/insurance/create`, options);
};

export const updateInsurance = (id: string, data: ProductUpdate) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return fetchJSON(`${ENP.PRODUCT_API}/insurance/update/${id}`, options);
};

export const deleteInsurance = (id: string) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.PRODUCT_API}/insurance/delete/${id}`, options);
};
