import { cp } from 'helpers/chainParse';
import toast from 'react-hot-toast';
import { getAccessToken } from './authUtils';

export const EXPIRE_TIME_KEY = 'logoutTime';

const getExpireTime = () => {
  const json = localStorage.getItem(EXPIRE_TIME_KEY);
  const milliSeconds = parseInt(json || '', 10);

  if (milliSeconds != null && !Number.isNaN(milliSeconds)) {
    return milliSeconds;
  }
  return null;
};

export const isExpiredToken = () => {
  const expireTime = getExpireTime();
  if (!expireTime) return true;

  const expiresTime = new Date(expireTime);
  const today = new Date();
  return expiresTime < today;
};

const fetchJWT = async <T>(url: string, options: any = {}, params: any = {}, callback = () => {}): Promise<T> => {
  const newUrl = new URL(url);
  newUrl.search = new URLSearchParams(params).toString();

  try {
    const response = await fetch(newUrl, options);
    if (response.status !== 200) {
      throw new Error(await response.json());
    }
    return await response.json();
  } catch (error: any) {
    if (typeof error === 'object' && typeof error?.then === 'function') {
      error.then((err: any) => {
        if (options.showError !== false && err.message && err.status !== 403 && err.status !== 401) {
          toast.error(cp(err, ['message']));
        }
      });
      // throw error;
    }
    throw error;
  }
};

const fetchJSON = async <T>(url: string, options: any = {}, params: any = {}, callback = () => {}): Promise<T> => {
  const newUrl = new URL(url);
  newUrl.search = new URLSearchParams(params).toString();

  if (!options.headers) options.headers = getHeaderWithAccessToken();

  try {
    const response = await fetch(newUrl, options);
    if (response.status !== 200) {
      throw new Error(await response.json());
    }
    return await response.json();
  } catch (error: any) {
    if (typeof error === 'object' && typeof error.then === 'function') {
      error.then((err: any) => {
        if (options.showError !== false && err.message && err.status !== 403 && err.status !== 401) {
          toast.error(cp(err, ['message']));
        }
      });
      // throw error;
    }
    throw error;
  }
};

const fetchSuccessJSON = <T>(url: string, options: any = {}, params: any = {}, callback = () => {}): Promise<T> => {
  const newUrl = new URL(url);
  newUrl.search = new URLSearchParams(params).toString();

  if (!options.headers) options.headers = getHeaderWithAccessToken();

  return fetch(newUrl, options)
    .then(async (response) => {
      if (response.status !== 200) {
        throw new Error(await response.json());
      }

      return response.json();
    })
    .catch((error: any) => {
      if (options.showError === false) {
        return;
      }

      error.then((err: any) => toast.error(cp(err, ['message'])));
    });
};

const formatParams = (params: any) => {
  return Object.keys(params)
    .map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
    })
    .join('&');
};

const getHeaderWithAccessToken = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAccessToken()}`,
  };
};

// TODO: use this in all place load file with token
const fetchFile = async (url: string, options: any = {}, params: any = {}): Promise<Blob> => {
  const newUrl = new URL(url);
  newUrl.search = new URLSearchParams(params).toString();

  if (!options.headers) options.headers = {};
  options.headers.Authorization = `Bearer ${getAccessToken()}`;

  const response = await fetch(newUrl, options);
  if (response.status !== 200) {
    throw new Error('Cannot fetch');
  }
  return response.blob();
};

const fetchPublicFile = async (url: string) => {
  const response = await fetch(url);
  if (response.status !== 200) {
    throw new Error('Cannot fetch');
  }
  return response.blob();
};

export {
  fetchJWT,
  fetchJSON,
  fetchSuccessJSON,
  formatParams,
  getAccessToken,
  getHeaderWithAccessToken,
  fetchFile,
  fetchPublicFile,
};
