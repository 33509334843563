import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAccessToken } from 'helpers/authUtils';
import { ENP } from 'constants/api';

export const customFieldApi = createApi({
  reducerPath: 'customFieldApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ENP.LEAD_API,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${getAccessToken()}`);
      return headers;
    },
  }),
  tagTypes: ['customFieldTagGroups'],
  endpoints: (builder) => ({
    fetchCustomFieldTabs: builder.query({
      query: () => `setting/custom-field/tab/list`,
    }),
    fetchCustomFieldTabGroups: builder.query({
      query: () => `setting/custom-field/tab/group/list`,
      providesTags: ['customFieldTagGroups'],
    }),

    createCustomField: builder.mutation({
      query: (data) => ({
        url: `setting/custom-field/create`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['customFieldTagGroups'],
    }),
    updateCustomField: builder.mutation({
      query: (data) => ({
        url: `setting/custom-field/${data.name}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['customFieldTagGroups'],
    }),
    deleteCustomField: builder.mutation({
      query: (name: string) => ({
        url: `setting/custom-field/${name}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['customFieldTagGroups'],
    }),

    updateFieldOrder: builder.mutation({
      query: (data: { source: string; target: string }) => ({
        url: `setting/custom-field/order`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['customFieldTagGroups'],
    }),

    updateFieldTabOrder: builder.mutation({
      query: (data: { source: string; target: string }) => ({
        url: `setting/custom-field-tab/order`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['customFieldTagGroups'],
    }),
  }),
});

export const {
  useFetchCustomFieldTabsQuery,
  useFetchCustomFieldTabGroupsQuery,
  useCreateCustomFieldMutation,
  useUpdateCustomFieldMutation,
  useDeleteCustomFieldMutation,

  useUpdateFieldOrderMutation,
  useUpdateFieldTabOrderMutation,
} = customFieldApi;
