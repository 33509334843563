import { fetchJSON, getAccessToken } from 'helpers/api';
import { ENP } from 'constants/api';

/* Calendar API */
export const fetchCalendarEvents = (params) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.CALENDAR_API}/v1/event/pagination/get-all`, options, params);
};

export const fetchCalendarEventsByDate = (params) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.CALENDAR_API}/v1/event/event/pagination`, options, params);
};

export const fetchCalendarEvent = (eventId) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.CALENDAR_API}/v1/event${eventId}`, options);
};

export const createCalendarEvent = (data) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.CALENDAR_API}/v1/event/create`, options);
};

export const updateCalendarEvent = (eventId, data) => {
  const options = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.CALENDAR_API}/v1/event/${eventId}`, options);
};

export const deleteCalendarEvent = (eventId) => {
  const options = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.CALENDAR_API}/v1/event/${eventId}`, options);
};
