import { AutoGraph } from '@mui/icons-material';
import { lazy } from 'react';

const Kpi = lazy(() => import('pages/kpi'));

/**
 * @typedef {import('router/routes').NxRoute} NxRoute
 * @type {NxRoute}
 */
const kpiRoutes = {
  path: '/kpi',
  name: 'menuParent:kpi',
  icon: AutoGraph,
  Component: Kpi,
};

export default kpiRoutes;
