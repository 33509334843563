import { fetchJSON, getAccessToken } from 'helpers/api';
import { ENP } from 'constants/api';

/* Loan debt API */
export const fetchAccounts = (params) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/partner`, options, params);
};

export const fetchPartners = (params) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/partner/get-all/paging`, options, params);
};

export const fetchPartnersByFilter = (filter, params) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(filter),
  };

  return fetchJSON(`${ENP.LEAD_API}/partner/filter`, options, params);
};

export const fetchPartnersByGroup = (groupId) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/partner/group/${groupId}`, options);
};

export const fetchPartnerDetail = (id) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/partner/${id}`, options);
};

export const createPartner = (data) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.LEAD_API}/partner/create`, options);
};

export const updatePartner = (id, data) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.LEAD_API}/partner/${id}/update`, options);
};

export const fetchPayments = (params) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/partner`, options, params);
};

export const fetchLeadValid = ({ code, params }) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/partner/report/lead-valid/count/${code}`, options, params);
};

export const fetchLeadStatus = ({ code, params }) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/partner/report/lead-status/count/${code}`, options, params);
};

export const fetchLeadReportCount = ({ code, params }) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/partner/report/day/count/${code}`, options, params);
};

export const fetchEffectivePartner = (data) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.LEAD_API}/partner/report/disbursed/month`, options);
};

export const fetchLeadDisbursement = ({ code, params }) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/partner/report/disbursement/${code}`, options, params);
};
