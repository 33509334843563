export const ADMIN_USERNAME = 'admin';

export const ACCOUNT_SVC = 'svc-acc';

export const ACCOUNT_LOGIN_WEB = 'r:app:web';

export const ACCOUNT_VIEW_ALL = 'r:admin-account:view-all';
export const ACCOUNT_CREATE = 'r:admin-account:update';
export const ACCOUNT_UPDATE = 'r:admin-account:update';
export const ACCOUNT_DELETE = 'r:admin-account:delete';
export const ACCOUNT_ACTIVATE = 'r:admin-account:activate';
export const ACCOUNT_DEACTIVATE = 'r:admin-account:deactivate';
export const ACCOUNT_SET_GROUP = 'r:admin-account:set-group';
export const ACCOUNT_CHANGE_PASSWORD = 'r:admin-account:change-password';

export const GROUP_VIEW_ALL = 'r:admin-group:view-all';
export const GROUP_VIEW = 'r:admin-group:view';
export const GROUP_CREATE = 'r:admin-group:create';
export const GROUP_UPDATE = 'r:admin-group:update';
export const GROUP_DELETE = 'r:admin-group:delete';

export const ROLE_VIEW_ALL = 'r:admin-role:view-all';
export const ROLE_CREATE = 'r:admin-role:create';
export const ROLE_UPDATE = 'r:admin-role:update';
export const ROLE_DELETE = 'r:admin-role:delete';

export const COMMON_SVC = 'svc-common';

export const COMMON_CREATE = 'r:common:create';
export const COMMON_UPDATE = 'r:common:update';
export const COMMON_DELETE = 'r:common:delete';

export const CALENDAR_SVC = 'svc-calendar';
export const CALENDAR_SETTING = 'r:calendar:setting';

export const LOGGER_SVC = 'svc-logger';
export const LOGGER_VIEW_ALL = 'r:activity-log:view-all';

export const PRODUCT_SVC = 'svc-product';

export const PRODUCT_GROUP_VIEW_LIST = 'r:product-group:view-list';
export const PRODUCT_GROUP_VIEW_DETAIL = 'r:product-group:view-detail';
export const PRODUCT_GROUP_CREATE = 'r:product-group:create';
export const PRODUCT_GROUP_UPDATE = 'r:product-group:update';
export const PRODUCT_GROUP_DELETE = 'r:product-group:delete';

export const PRODUCT_VIEW_LIST = 'r:product:view-list';
export const PRODUCT_VIEW_DETAIL = 'r:product:view-detail';
export const PRODUCT_CREATE = 'r:product:create';
export const PRODUCT_UPDATE = 'r:product:update';
export const PRODUCT_DELETE = 'r:product:delete';
export const PRODUCT_ACTIVATE = 'r:product:activate';
export const PRODUCT_UPLOAD_DOCUMENT = 'r:product:upload-document';

export const LEAD_SVC = 'svc-lead';

export const CUSTOMER_IMPORT = 'r:customer:import';

export const LEAD_VIEW_LIST = 'r:lead:view-list';
export const LEAD_VIEW_DETAIL = 'r:lead:view-detail';
export const LEAD_EXPORT = 'r:lead:export';
export const LEAD_CREATE = 'r:lead:create';
export const LEAD_UPDATE = 'r:lead:update';
export const LEAD_UPDATE_DEMAND = 'r:lead:update-demand';
export const LEAD_UPDATE_S37 = 'r:lead:update-s37';
export const LEAD_UPDATE_CIC = 'r:lead:update-cic';
export const LEAD_VIEW_FIELD_NOTE = 'r:lead:view-field-note';
export const LEAD_UPDATE_FIELD_NOTE = 'r:lead:update-field-note';
export const LEAD_COMPLETE = 'r:lead:complete';
export const LEAD_ASSIGN = 'r:lead:assign';

export const LEAD_CAMPAIGN_IMPORT = 'r:lead-import:campaign';
export const LEAD_SELF_IMPORT = 'r:lead-import:self';
export const LEAD_ENDED_IMPORT = 'r:lead-import:ended';
export const LEAD_IMPORT_HISTORY = 'r:lead-import:view-history';

export const PARTNER_VIEW_LIST = 'r:partner:view-list';
export const PARTNER_VIEW_DETAIL = 'r:partner:view-detail';
export const PARTNER_CREATE = 'r:partner:create';
export const PARTNER_UPDATE = 'r:partner:update';
export const PARTNER_DELETE = 'r:partner:delete';
export const PARTNER_REPORT = 'r:partner:report';

export const CAMPAIGN_VIEW_LIST = 'r:campaign:view-list';
export const CAMPAIGN_VIEW_DETAIL = 'r:campaign:view-detail';
export const CAMPAIGN_CREATE = 'r:campaign:create';
export const CAMPAIGN_UPDATE = 'r:campaign:update';
export const CAMPAIGN_DELETE = 'r:campaign:delete';
export const CAMPAIGN_ACTIVATE = 'r:campaign:activate';
export const CAMPAIGN_FINISH = 'r:campaign:finish';
export const CAMPAIGN_CANCEL = 'r:campaign:cancel';
export const CAMPAIGN_REPORT = 'r:campaign:report';

export const KPI_VIEW = 'r:kpi:view';
export const KPI_CREATE = 'r:kpi:create';
export const KPI_UPDATE = 'r:kpi:update';
export const KPI_DELETE = 'r:kpi:delete';
export const KPI_VIEW_LOG = 'r:kpi:view-log';

export const CUSTOM_FIELD_CREATE = 'r:custom-field:create';
export const CUSTOM_FIELD_UPDATE = 'r:custom-field:update';
export const CUSTOM_FIELD_DELETE = 'r:custom-field:delete';

// TODO:
export const DOCUMENT_VIEW = 'r:document:view';
export const DOCUMENT_UPLOAD = 'r:document:upload';

export const DOCUMENT_CHECK_VIEW = 'r:additional-request:view';
export const DOCUMENT_CHECK_UPDATE = 'r:additional-request:update';

export const EVENT_CREATE = 'r:event:create';
export const EVENT_UPDATE = 'r:event:update';

export const NOTE_CREATE = 'r:note:create';
export const NOTE_UPDATE = 'r:note:update';

export const LEAD_FLOW_VIEW = 'r:lead-flow:view';
export const LEAD_FLOW_CREATE = 'r:lead-flow:create';

export const ACTIVITY_VIEW_LIST = 'r:activity:view-list';
export const ACTIVITY_CREATE = 'r:activity:create';
export const ACTIVITY_UPDATE = 'r:activity:update';
export const ACTIVITY_EMAIL = 'r:activity:email';

export const ACTIVITY_VIEW_KPI = 'r:activity:view-kpi';
export const ACTIVITY_VIEW_REPORT = 'r:activity:view-report';
export const ACTIVITY_EXPORT_REPORT = 'r:activity:export-report';

export const KPI_REPORT_VIEW = 'r:kpi-report:view';

export const CONVERSION_FUNNEL_SETUP = 'r:conversion-funnel:setup';

export const CONVERSION_REPORT_VIEW = 'r:conversion-report:view';
export const CONVERSION_REPORT_EXPORT = 'r:conversion-report:export';

export const ASSIGN_RULE_VIEW = 'r:assign-rule:view'; // TODO:
export const ASSIGN_RULE_CREATE = 'r:assign-rule:create';
export const ASSIGN_RULE_UPDATE = 'r:assign-rule:update';
export const ASSIGN_RULE_ACTIVATE = 'r:assign-rule:activate';
export const ASSIGN_RULE_DELETE = 'r:assign-rule:delete';

export const ASSIGN_REPORT_SUMMARY = 'r:assign-report:summary';
export const ASSIGN_REPORT_VIEW = 'r:assign-report:view';
export const ASSIGN_REPORT_EXPORT = 'r:assign-report:export';

export const DISBURSEMENT_REPORT_SUMMARY = 'r:disbursement-report:summary';
export const DISBURSEMENT_REPORT_VIEW = 'r:disbursement-report:view';
export const DISBURSEMENT_REPORT_EXPORT = 'r:disbursement-report:export';
export const DISBURSEMENT_REPORT_VIEW_OWNER = 'r:disbursement-report:view-owner';
export const DISBURSEMENT_REPORT_VIEW_GROUP = 'r:disbursement-report:view-group';
export const DISBURSEMENT_REPORT_EXPORT_OWNER = 'r:disbursement-report:export-owner';
export const DISBURSEMENT_REPORT_EXPORT_GROUP = 'r:disbursement-report:export-group';

export const DASHBOARD_SUMMARY = 'r:dashboard:summary';
export const DASHBOARD_MANAGER_SUMMARY = 'r:dashboard:manager-summary';

export const DASHBOARD_COMPLETE_RATE_OWNER = 'r:dashboard:complete-rate-owner';
export const DASHBOARD_COMPLETE_RATE_GROUP = 'r:dashboard:complete-rate-group';
export const DASHBOARD_DISBURSED_AMOUNT = 'r:dashboard:disbursed-amount';
export const DASHBOARD_DISBURSED_LEAD = 'r:dashboard:disbursed-lead';
export const DASHBOARD_DISBURSED_AMOUNT_MONTHLY = 'r:dashboard:monthly-disbursed-lead';
export const DASHBOARD_DISBURSED_LEAD_MONTHLY = 'r:dashboard:monthly-disbursed-amount';
export const DASHBOARD_DISBURSED_OWNER = 'r:dashboard:disbursed-owner';

export const DASHBOARD_CONVERSION = 'r:dashboard:conversion';
