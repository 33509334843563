import { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

// Loader component
import withLayout from 'components/Layout';
import Loader from './loader/Loader';
import { privateRoutes, publicRoutes } from './routes';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import errorRoutes from './routes/errorRoutes';
import useRouteConfig from './hooks/useRouters';
import LandingRoute from './LandingRoute';

const Error404 = lazy(() => import('pages/other/Error404'));

/**
 * @typedef {import('router/routes/index').NxRoute} NxRoute
 * @typedef {import('router/hooks/useRouters').Page} Page
 */

/**
 * @param {NxRoute[]} routes
 * @param {Page[]} pages
 * @returns {NxRoute[]}
 */
const addFlagToAllowedRoute = (routes, pages) => {
  return routes.map((route) => {
    if (pages.some((page) => page.path === route.path)) {
      route.isAllowed = true;
    }
    return route;
  });
};

function NxRouterContainer() {
  const [loadingMenu, setLoadingMenu] = useState(true);
  const { getPages, hasMenu, setReduxRouteConfig, getLocalStorageRouteConfig } = useRouteConfig();

  const privateRouteList = useMemo(
    /** @return {NxRoute[]} */
    () => addFlagToAllowedRoute(privateRoutes, getPages()),
    [getPages]
  );

  /**
   * Check if the application has a menu, if it doesn't,
   * then it retrieves the route configuration from local storage and
   * sets it into Redux state. The hook will run whenever `hasMenu` changes.
   */
  useEffect(
    () => {
      if (!hasMenu()) {
        const localConfig = getLocalStorageRouteConfig();
        if (localConfig) {
          setReduxRouteConfig(localConfig);
        }
      }
      setLoadingMenu(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasMenu]
  );

  if (loadingMenu) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {publicRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            element={<PublicRoute Component={withLayout(route.Component)} />}
          />
        ))}

        {privateRouteList.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact ?? true}
            element={
              <PrivateRoute
                roles={route.roles}
                resource={route.resource}
                isAllowed={route.isAllowed}
                Component={withLayout(route.Component)}
              />
            }
          />
        ))}

        {errorRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={<route.Component />} />
        ))}

        {/* <LandingRoute /> */}
        <Route path="/landing" exact element={<LandingRoute />} />

        <Route path="*" element={<Error404 />} />
      </Routes>
    </Suspense>
  );
}

export default NxRouterContainer;
