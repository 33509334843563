// @flow
import { addSeconds } from 'date-fns';
import { isAvailableArray } from './arrayUtils';
import { createLCStorageKey } from './keyUtils';
import { decrypt, encrypt } from './cryptoUtils';

const isCallUser = () => {
  const user = getLoggedInUser();
  const loggedInData = getLoggedIn();

  if (!user || !loggedInData || !user.callUsername || !user.callPassword) {
    return false;
  }

  return true;
};

const getLoggedInUser = () => {
  try {
    return JSON.parse(localStorage.getItem('user'));
  } catch {
    return null;
  }
};

/**
 * Retrieves the logged-in user's data from local storage.
 *
 * @returns {object|null} - Returns the logged-in user's data if it exists,
 * otherwise returns null.
 */
const getLoggedIn = () => {
  try {
    return JSON.parse(localStorage.getItem('loggedIn'));
  } catch {
    return null;
  }
};

const getAccessToken = () => {
  return getLoggedIn().access_token;
};

const setLoggedInUser = (user) => {
  if (!user) {
    localStorage.removeItem('user', { path: '/', domain: window.location.hostname });

    return;
  }

  localStorage.setItem('user', JSON.stringify(user), {
    path: '/',
    domain: window.location.hostname,
  });
};

const setLoggedIn = (data) => {
  if (!data) {
    localStorage.removeItem('loggedIn', { path: '/', domain: window.location.hostname });

    return;
  }

  const expiresTime = addSeconds(new Date(), data.expires_in);
  data = { access_token: data.access_token, refresh_token: data.refresh_token, expiresTime };
  localStorage.setItem('loggedIn', JSON.stringify(data), {
    path: '/',
    domain: window.location.hostname,
  });
};

const isManager = () => {
  const user = getLoggedInUser();
  return user.group && user.group.leader && user.group.leader.id === user.id;
};

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
  const user = getLoggedInUser();
  const loggedInData = getLoggedIn();

  if (!user || !loggedInData) {
    return false;
  }

  return true;
};

const isDarkMode = () => {
  try {
    return JSON.parse(localStorage.getItem('isDarkMode'));
  } catch {
    return null;
  }
};

const NOTIFICATION_TOKEN_KEY = createLCStorageKey('notificationSubscribedToken');

const getNotificationSubscribedToken = () => {
  try {
    return localStorage.getItem(decrypt(NOTIFICATION_TOKEN_KEY));
  } catch {
    return null;
  }
};

const subscribeNotificationToken = (token) => {
  localStorage.setItem(NOTIFICATION_TOKEN_KEY, encrypt(token));
};

const unsubscribeNotificationToken = () => {
  localStorage.removeItem(NOTIFICATION_TOKEN_KEY);
};

//= ===========================================================================
// PERMISSION HELPER (RESOURCE & ROLE)
//= ===========================================================================

/**
 * @typedef {Object} Resource
 *
 * An object representing the roles associated with a particular resource.
 *
 * @property {Array.<string>} roles - An array of role identifiers for the specific resource.
 */

/**
 * @typedef {Object.<string, Resource>} ResourceAccess
 *
 * Object structure representing the access configuration of a user.
 * Each key is the name of a resource (service, application, or module), and its
 * corresponding value is a Resource object, which contains a "roles" array.
 */

/**
 * Retrieves the payload from the token of the logged in user.
 *
 * @returns {object|null} - Returns the decoded JSON payload of the token,
 * or null if the token is not available or cannot be parsed.
 */
const getTokenPayload = () => {
  const loginData = getLoggedIn();

  if (!loginData) {
    return null;
  }

  const tokenPayload = loginData.access_token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');

  try {
    return JSON.parse(window.atob(tokenPayload));
  } catch (error) {
    return null;
  }
};

/**
 * Checks if the user has the specified role for a given resource.
 *
 * @param {string} resource - The resource to check role against.
 * If the resource is "*", it checks the role across all resources.
 * @param {string} role - The role to check.
 * @returns {boolean} - Returns true if the user has the specified role for the resource,
 * false otherwise.
 */
const hasRole = (resource, role) => {
  /** @type {ResourceAccess} */
  const resourceAccess = getTokenPayload()?.resource_access;

  if (!resourceAccess) {
    return false;
  }

  if (resource === '*') {
    return Object.values(resourceAccess).some((resource) => resource?.roles.includes(role));
  }

  return resourceAccess[resource]?.roles.includes(role) || false;
};

/**
 * Checks if the user has any of the specified roles.
 *
 * @param {string} resource - The resource to check roles against.
 * @param {Array.<string>} roles - An array of roles.
 * @returns {boolean} - Returns true if the user has at least one of the specified roles,
 * or if the roles array is not provided or empty. Otherwise, returns false.
 */
const hasRolesOr = (resource, roles) => {
  if (!isAvailableArray(roles)) {
    return true;
  }
  return roles.some((role) => hasRole(resource, role));
};

/**
 * Checks if the user has all of the specified roles.
 *
 * @param {string} resource - The resource to check roles against.
 * @param {Array.<string>} roles - An array of roles.
 * @returns {boolean} - Returns true if the user has all of the specified roles,
 * or if the roles array is not provided or empty. Otherwise, returns false.
 */
const hasRolesAnd = (resource, roles) => {
  if (!isAvailableArray(roles)) {
    return true;
  }
  return roles.every((role) => hasRole(resource, role));
};

export {
  isUserAuthenticated,
  isCallUser,
  getLoggedInUser,
  setLoggedInUser,
  setLoggedIn,
  getLoggedIn,
  getAccessToken,
  getTokenPayload,
  hasRole,
  hasRolesOr,
  hasRolesAnd,
  isManager,
  isDarkMode,
  getNotificationSubscribedToken,
  subscribeNotificationToken,
  unsubscribeNotificationToken,
};
