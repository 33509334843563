const LOCAL_STORAGE_PREFIX = import.meta.env.VITE_PREFIX;

/**
 *
 * @param {string} key
 * @returns {string}
 */
const createLCStorageKey = (key) => {
  return `${LOCAL_STORAGE_PREFIX}_${key}`;
};

export { createLCStorageKey };
