import { lazy } from 'react';
import { CalendarMonthOutlined } from '@mui/icons-material';

const CalendarComponent = lazy(() => import('pages/collection/calendar'));

/**
 * @typedef {import('router/routes').NxRoute} NxRoute
 * @type {NxRoute}
 */
const calendarRoutes = {
  path: '/calendar',
  name: 'menuParent:calendar',
  icon: CalendarMonthOutlined,
  Component: CalendarComponent,
  resource: 'svc-calendar',
  // roles: ["u_event"],
};

export default calendarRoutes;
