const chainParse = (obj, attrArr, defaultValue = null) => {
  if (!obj || typeof obj !== 'object') {
    return defaultValue;
  }

  let cloneObj = { ...obj };

  for (let i = 0; i < attrArr.length; i += 1) {
    cloneObj = cloneObj[attrArr[i]];
    if (typeof cloneObj === 'undefined' || cloneObj === null || cloneObj === '') return defaultValue;
  }

  return cloneObj;
};

export { chainParse as cp };
