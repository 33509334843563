import { IThemeOptions } from './themeOptions';

const textLight = {
  primary: 'rgba(52, 49, 76, 1)',
  secondary: 'rgba(52, 49, 76, 0.54)',
  disabled: 'rgba(52, 49, 76, 0.38)',
  hint: 'rgba(52, 49, 76, 0.38)',
};

/* eslint-disable no-unused-vars */
const secondaryColor = {
  light: '#f9a352',
  main: '#ff9e43',
  dark: '#ff932e',
  contrastText: textLight.primary,
};

export const themeColors: { light: IThemeOptions; dark: IThemeOptions } = {
  light: {
    palette: {
      mode: 'light',
      primary: {
        main: '#0068e0',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#070d31',
        contrastText: textLight.primary,
      },
    },
  },
  dark: {
    palette: {
      mode: 'dark',
      primary: {
        main: '#1a2038',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#ff9e43',
        contrastText: textLight.primary,
      },
      background: {
        paper: '#222A45',
        default: '#1a2038',
      },
    },
  },
};
