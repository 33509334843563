import { lazy } from 'react';

/**
 * @typedef {import('router/routes').NxRoute} NxRoute
 */

const Home = lazy(() => import('pages/home'));

/** @type {NxRoute} */
const homeRoute = {
  path: '/',
  name: 'Trang chủ',
  Component: Home,
  exact: true,
  isAllowed: true,
};

export default homeRoute;
