import { cloneDeep } from 'lodash-es';
import authRoutes from './authRoutes';
import calendarRoutes from './calendarRoutes';
import customerRoutes from './customerRoutes';
import expertiseRoutes from './expertiseRoutes';
import kpiRoutes from './kpiRoutes';
import marketingRoutes from './marketingRoutes';
import productRoutes from './productRoutes';
import reportRoutes from './reportRoutes';
import sellRoutes from './sellRoutes';
import settingRoutes from './settingRoutes';
import dashboardRoute from './dashboardRoute';
import homeRoute from './homeRoute';

/**
 * Lazy-loaded component type
 * @typedef {React.LazyExoticComponent<React.ComponentType<any>>} ReactLazy
 */

/**
 * @typedef {Object} NxRoute
 * @property {string} path
 * @property {string} name
 * @property {ReactLazy} Component
 * @property {React.Component} [icon]
 * @property {boolean} [isPublic]
 * @property {boolean} [hasDetail]
 * @property {boolean} [exact=true]
 * @property {boolean} [isAllowed]
 * @property {Array<NxRoute>} children
 */

/** @type {Array<NxRoute>} */
const allRoutes = [
  homeRoute,
  authRoutes,
  dashboardRoute,
  reportRoutes,
  productRoutes,
  marketingRoutes,
  sellRoutes,
  customerRoutes,
  expertiseRoutes,
  calendarRoutes,
  kpiRoutes,
  settingRoutes,
];

/**
 *
 * @param {Array<NxRoute>} routes
 * @returns {NxRoute[]}
 */
const getAllFlattenRoutes = (routes) => {
  const children = [];

  routes.forEach((route) => {
    if (route.children) {
      const result = getAllFlattenRoutes(route.children);
      children.push(...result);
      delete route.children;
    }
    children.push(route);
  });

  return children;
};

/**
 *
 * @param {Array<NxRoute>} routes
 * @returns {NxRoute[]}
 */
const getRenderedRoutes = (routes) => {
  const children = [];

  routes.forEach((route) => {
    if (route.children) {
      const result = getRenderedRoutes(route.children);
      children.push(...result);
      delete route.children;
    }

    if (route.Component) {
      children.push(route);
    }
  });

  return children;
};

/** @type {Array<NxRoute>} */
const allFlattenRoutes = getAllFlattenRoutes(cloneDeep(allRoutes));

/** @type {NxRoute[]} */
const renderedRoutes = getRenderedRoutes(cloneDeep(allRoutes));

const publicRoutes = renderedRoutes.filter((route) => route.isPublic);
const privateRoutes = renderedRoutes.filter((route) => !route.isPublic);

export { allFlattenRoutes, publicRoutes, privateRoutes };
