import { Suspense, lazy, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
// import HealthCheck from 'healthCheck';
import { updateUserStatus } from 'redux/actions';
import { USER_STATES } from 'constants/userState';
import NexustiDialerProvider, {
  WEBPHONE_PROVIDER,
  WEBPHONE_SDK,
} from 'components/dialer/nexusti/nexusti-dialer-provider';
import { getLoggedInUser } from 'helpers/authUtils';
import NxRouterContainer from 'router';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material';
import { merge } from 'lodash-es';
import { themeColors } from 'layouts/theme/themeColors';
import themeOptions, { IThemeOptions } from 'layouts/theme/themeOptions';
import { ColorModeContext } from 'ColorMode';

const Notification = lazy(() => import('Notification'));

/**
 * Main app component
 */

function App() {
  // const isProduction = import.meta.env.NODE_ENV === 'production';

  const user = getLoggedInUser();

  const dispatch = useDispatch();

  const handleChangeStatus = (status: string) => {
    dispatch(updateUserStatus(status));
  };

  const onCalling = () => {
    handleChangeStatus(USER_STATES.inCall);
  };

  const onAvailable = () => {
    handleChangeStatus(USER_STATES.available);
  };

  const [mode, setMode] = useState<'light' | 'dark'>('light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
      mode,
    }),
    [mode]
  );

  const theme = useMemo(() => createTheme(merge({}, themeColors[mode], themeOptions) as IThemeOptions), [mode]);

  return (
    <StyledEngineProvider injectFirst>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <Toaster />
          <Suspense fallback={null}>
            <Notification />
          </Suspense>
          <NexustiDialerProvider
            webPhoneList={[
              {
                type: WEBPHONE_SDK.PBX,
                provider: WEBPHONE_PROVIDER.CGV,
                username: user?.callUsername,
                password: user?.callPassword,
                domain: 'cloud.telebot.vn',
              },
            ]}
            onCalling={onCalling}
            onAvailable={onAvailable}
          >
            <BrowserRouter>
              <NxRouterContainer />
            </BrowserRouter>
          </NexustiDialerProvider>
          {/* <HealthCheck /> */}
        </ThemeProvider>
      </ColorModeContext.Provider>
    </StyledEngineProvider>
  );
}

export default App;
