import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENP } from 'constants/api';
import { getAccessToken } from 'helpers/authUtils';

export const activityCodeApi = createApi({
  reducerPath: 'activityCodeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ENP.LEAD_API,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${getAccessToken()}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchCallCode: builder.query({
      query: () => `code/call`,
    }),
    createCallCode: builder.mutation({
      query: (data) => ({
        url: `code/call/create`,
        method: 'POST',
        body: data,
      }),
    }),
    updateCallCode: builder.mutation({
      query: (data) => ({
        url: `code/call/${data.id}/update`,
        method: 'PUT',
        body: data,
      }),
    }),
    deleteCallCode: builder.mutation({
      query: (id: string) => ({
        url: `code/call/${id}`,
        method: 'DELETE',
      }),
    }),

    fetchFieldCode: builder.query({
      query: () => `/code/field`,
    }),
    createFieldCode: builder.mutation({
      query: (data) => ({
        url: `code/field/create`,
        method: 'POST',
        body: data,
      }),
    }),
    updateFieldCode: builder.mutation({
      query: (data: any) => ({
        url: `code/field/${data.id}/update`,
        method: 'PUT',
        body: data,
      }),
    }),
    deleteFieldCode: builder.mutation({
      query: (id: string) => ({
        url: `code/field/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchCallCodeQuery,
  useCreateCallCodeMutation,
  useUpdateCallCodeMutation,
  useDeleteCallCodeMutation,

  useFetchFieldCodeQuery,
  useCreateFieldCodeMutation,
  useUpdateFieldCodeMutation,
  useDeleteFieldCodeMutation,
} = activityCodeApi;
