import { FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENP } from 'constants/api';
import { getAccessToken } from 'helpers/authUtils';

export const productApi = createApi({
  reducerPath: 'productApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ENP.PRODUCT_API,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${getAccessToken()}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchProductGroup: builder.query({
      query: () => `product/group/get-all`,
    }),
    filterProducts: builder.query({
      query: ({ filter, pagination }) => ({
        url: `product/filter`,
        method: 'POST',
        params: pagination,
        body: filter,
      }),
    }),
    fetchProductDetail: builder.query({
      query: (productCode) => `product/code/${productCode}`,
    }),

    createProduct: builder.mutation({
      async queryFn({ product, uploadDocuments }, _queryApi, _extraOptions, fetch) {
        let result = await fetch({
          url: `product`,
          method: 'POST',
          body: product,
        });
        if (result.error) return { error: result.error as FetchBaseQueryError };

        if (product.logoUrl) {
          result = await fetch({
            url: `product/${product.code}/logo`,
            method: 'POST',
            body: product,
          });
        }

        // TODO: Get productCode of created product, then upload documents if exist
        return result.data ? { data: result.data } : { error: result.error as FetchBaseQueryError };
      },
    }),
    updateProduct: builder.mutation({
      async queryFn({ product }, _queryApi, _extraOptions, fetch) {
        let result = await fetch({
          url: `product/${product.code}`,
          method: 'PUT',
          body: product,
        });
        if (result.error) return { error: result.error as FetchBaseQueryError };

        if (product.logoUrl) {
          result = await fetch({
            url: `product/${product.code}/logo`,
            method: 'POST',
            body: product,
          });
        }

        return result.data ? { data: result.data } : { error: result.error as FetchBaseQueryError };
      },
    }),

    fetchInsurances: builder.query({
      query: ({ pagination }) => ({
        url: `insurance/get-all/pagination`,
        method: 'GET',
        params: pagination,
      }),
    }),
  }),
});

export const {
  useFetchProductGroupQuery,

  useFilterProductsQuery,
  useFetchProductDetailQuery,
  useLazyFilterProductsQuery,
  useCreateProductMutation,
  useUpdateProductMutation,

  useFetchInsurancesQuery,
  useLazyFetchInsurancesQuery,
} = productApi;
