// @flow
import { Component, ComponentType, lazy, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { isUserAuthenticated } from 'helpers/authUtils';
import { resetLoading } from 'redux/actions';

// Layouts
const AuthLayout = lazy(() => import('layouts/Auth'));
const VerticalLayout = lazy(() => import('layouts/Vertical'));

/**
 *
 * @returns {Component}
 */
const getLayout = () => {
  if (!isUserAuthenticated()) {
    return AuthLayout;
  }

  return VerticalLayout;
};

/**
 * Exports the component with layout wrapped to it
 * @param {ComponentType<any>} WrappedComponent
 * @returns {Component}
 */
const withLayout = (WrappedComponent) => {
  return function (props) {
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(resetLoading());
    }, [dispatch]);

    const Layout = getLayout();

    return (
      <Layout>
        <WrappedComponent {...props} />
      </Layout>
    );
  };
};

export default withLayout;
