import { fetchJSON, getAccessToken } from 'helpers/api';
import { ENP } from 'constants/api';

/* Loan debt API */

export const deleteLead = (leadId) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: 'DELETE',
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/${leadId}`, options);
};

export const resetLead = (leadId) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: 'POST',
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/reset/flow/${leadId}`, options);
};

export const resetLeadAndContract = (leadId) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: 'POST',
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/reset/flow/contract/${leadId}`, options);
};

export const updateLeadStatus = (leadId, status) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: 'PUT',
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/${leadId}/${status}`, options);
};

export const updateLeadAssignee = (leadId, assingee) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: 'PUT',
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/${leadId}/assign/${assingee}`, options);
};

export const fetchLeadListStatus = () => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/setting/flow/lead/status`, options);
};

export const fetchLeadListResult = () => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/setting/flow/lead/result`, options);
};

export const updateLeadDemand = (leadId, data) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/demand/${leadId}/`, options);
};

export const fetchLeadS37 = (leadId) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/s37/${leadId}`, options);
};

export const fetchLeadCIC = (leadId) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/cic/${leadId}`, options);
};

export const updateLeadS37 = (leadId, formData) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: 'PUT',
    body: formData,
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/s37/${leadId}/`, options);
};

export const updateLeadCIC = (leadId, formData) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: 'PUT',
    body: formData,
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/cic/${leadId}/`, options);
};

export const assignLead = (assignee, data) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/assign/${assignee}`, options);
};

export const autoAssignLead = () => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/assign/auto`, options);
};

export const fetchLeadSettingChecklist = () => {
  const option = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/setting/checklist/get-all`, option);
};

export const createLeadSettingChecklist = (data) => {
  const option = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.LEAD_API}/setting/checklist/create`, option);
};

export const updateLeadSettingChecklist = (name, data) => {
  const option = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ name, ...data }),
  };

  return fetchJSON(`${ENP.LEAD_API}/setting/checklist/update`, option);
};

export const deleteLeadSettingChecklist = (name) => {
  const option = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: 'DELETE',
  };

  return fetchJSON(`${ENP.LEAD_API}/setting/checklist/${name}/delete`, option);
};

export const fetchLeadSettingAssignStrategy = () => {
  const option = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/setting/assign-strategy/current`, option);
};

export const updateLeadSettingAssignStrategy = (assignStrategy) => {
  const option = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/setting/assign-strategy/update/${assignStrategy}`, option);
};

/* LEAD DOCUMENT API */

export const fetchLeadDocuments = (leadId, pagination) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/document/${leadId}`, options, pagination);
};

export const fetchRequiredDocumentTypes = (productCode) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/document/${productCode}`, options);
};

export const uploadLeadDocument = (leadId, documentType, documentName, file) => {
  const formData = new FormData();
  formData.append('file', file);

  const params = { documentType, documentName };

  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    body: formData,
  };

  return fetchJSON(`${ENP.LEAD_API}/document/${leadId}`, options, params);
};

export const validateProductInfoRequirement = (leadId) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/requirement/info/${leadId}`, options);
};

export const validateProductDocumentRequirement = (leadId) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/requirement/document/${leadId}`, options);
};

export const validateAdditionalDocumentApprove = (leadId) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/additional-request/${leadId}/check`, options);
};

export const validateAdditionalDocumentRequest = (leadId) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/additional-request/${leadId}/result`, options);
};

export const updateAdditionalDocumentRequest = ({ leadId, data }) => {
  const options = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.LEAD_API}/additional-request/${leadId}`, options);
};

/* LEAD IMPORT */

export const importLead = (data, checkOnly) => {
  const options = {
    method: 'POST',
    body: data,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };
  return fetchJSON(`${ENP.LEAD_API}/lead/import/user${checkOnly ? '/check' : ''}`, options);
};

export const importLeadOnCampaign = (data, checkOnly) => {
  const options = {
    method: 'POST',
    body: data,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/import${checkOnly ? '/check' : ''}`, options);
};

export const importEndedLead = (data, checkOnly) => {
  const options = {
    method: 'POST',
    body: data,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };
  return fetchJSON(`${ENP.LEAD_API}/lead/import/ended${checkOnly ? '/check' : ''}`, options);
};

export const fetchLeadImportHistory = (pagination) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/import`, options, pagination);
};

export const fetchLeadImportHistoryItems = (code, pagination, filter) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(filter),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/import/code/${code}`, options, pagination);
};

export const fetchLeadDedupAlerts = (leadId) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/alert/dedup/${leadId}`, options);
};

export const syncLeadWithCustomerIdCard = (leadId) => {
  const options = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/${leadId}/sync/customer/id-card`, options);
};

export const fetchLeadReportCount = (leadType, groupId) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/report/${leadType}/count/${groupId}`, options);
};

export const fetchAllProduct = () => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };
  return fetchJSON(`${ENP.PRODUCT_API}/product/get-all`, options);
};

/* CRM mCas API */

export const fetchMcasInfo = (leadId) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.CRM_MCAS_API}/info/${leadId}`, options);
};

export const updateMcasContractInfo = (leadId, contractId, cif) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.CRM_MCAS_API}/info/${leadId}/contract/${contractId}/cif/${cif}`, options);
};

export const createMcasCustomerLoan = (leadId) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.CRM_MCAS_API}/customer/create/${leadId}`, options);
};

export const updateMcasCustomerLoan = (leadId) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.CRM_MCAS_API}/customer/update/${leadId}`, options);
};

export const updateMcasCustomerDocument = (leadId) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.CRM_MCAS_API}/customer/document/${leadId}`, options);
};

export const reloadMcasContractInfo = (leadId, contractId) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.CRM_MCAS_API}/contract/${leadId}/${contractId}`, options);
};

export const fetchMission = () => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/setting/flow/lead/task`, options);
};

export const fetchRule = () => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/auto-assign/rule/get-all`, options);
};

export const createRule = (data) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.LEAD_API}/auto-assign/rule/create`, options);
};

export const updateRule = ({ id, data }) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.LEAD_API}/auto-assign/rule/update/${id}`, options);
};

export const autoAssignedLead = ({ id, data }) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.LEAD_API}/auto-assign/assign/auto/${id}`, options);
};

export const autoAssignedLeadFilter = ({ username, data }) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/assign/${username}/filter`, options);
};

export const activeAutoAssignRule = ({ id, data }) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.LEAD_API}/auto-assign/rule/activate/${id}`, options);
};

export const deActiveAutoAssignRule = ({ id, data }) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.LEAD_API}/auto-assign/rule/deactivate/${id}`, options);
};

export const fetchRecallList = (filter, pagination) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    params: pagination,
    body: JSON.stringify(filter),
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/filter`, options, pagination);
};

export const fetchRecallResult = (filter, pagination) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    params: pagination,
    body: JSON.stringify(filter),
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/new/filter`, options, pagination);
};

export const fetchRuleActive = () => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/auto-assign/rule/active`, options);
};

export const fetchAssignLeadAuto = (ruleId, leads) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(leads),
  };

  const filter = leads?.length ? '' : '/filter';

  return fetchJSON(`${ENP.LEAD_API}/auto-assign/assign/auto/${ruleId}${filter}`, options);
};

export const fetchAssignLeadManual = (username, leads) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(leads),
  };

  const filter = leads?.length ? '' : '/filter';

  return fetchJSON(`${ENP.LEAD_API}/lead/assign/${username}${filter}`, options);
};

export const settingsAutoAssign = (autoAssignRuleId) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    // body: JSON.stringify(users)
  };

  return fetchJSON(`${ENP.LEAD_API}/setting/new-lead/auto-assign/rule/${autoAssignRuleId}`, options);
};

export const deleteRule = (id) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    // body: JSON.stringify(users)
  };

  return fetchJSON(`${ENP.LEAD_API}/auto-assign/rule/delete/${id}`, options);
};

export const fetchVariable = () => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/activity/variable/get-all`, options);
};

export const SendEmailActivity = (data) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  };

  return fetchJSON(`${ENP.LEAD_API}/activity/email/send`, options);
};

export const fetchNumberTargetKPI = (data) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/kpi/number/target`, options);
};

export const fetchReportLead = ({ type, data }) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/report/new-lead/count/${type}`, options);
};

export const fetchReportLeadActivity = ({ type, data }) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/activity/report/count/${type}/CALL`, options);
};

export const fetchReportLeadMcas = ({ type, data }) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/report/contract-lead/count/${type}`, options);
};

export const fetchReportLeadDisbursedAmount = ({ type, data }) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/report/disbursed-amount/count/${type}`, options);
};

export const fetchReportLeadDisbursedLead = ({ type, data }) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/report/disbursed-lead/count/${type}`, options);
};

export const fetchReportLeadPartner = ({ type, data }) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/partner/report/disbursed/${type}`, options);
};

export const fetchConversionFunnel = () => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/conversion-funnel/get-all`, options);
};

export const getCategoryValues = (category) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.COMMON_API}/category/values/${category}`, options);
};

export const fetchDashboardSummary = () => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/report/dashboard/summary`, options);
};

export const fetchDashboardEmployeeSummary = (data) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/disbursement/report/dashboard/complete-rate/owner`, options);
};

export const fetchDashboardPosSummary = (data) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/disbursement/report/dashboard/complete-rate/group`, options);
};

export const fetchDashboardCountMonth = (data) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/disbursement/report/dashboard/count`, options);
};

export const fetchDashboardDisbursedSale = (data) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/disbursement/report/dashboard/sum`, options);
};

export const fetchDashboardDisbursedSaleMonthSum = (data) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/disbursement/report/dashboard/month/sum`, options);
};

export const fetchDashboardDisbursedProduct = (data) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/disbursement/report/dashboard/product`, options);
};

export const fetchDashboardLeadConversion = ({ id, data }) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/lead-conversion/report/dashboard/${id}`, options);
};

export const fetchDashboardDisbursementMonthCount = (data) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/disbursement/report/dashboard/month/count`, options);
};

export const fetchDashboardDisbursementCount = (data) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/disbursement/report/dashboard/count`, options);
};

// TT
export const fetchDashboardEmployeeRating = (data) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/disbursement/report/dashboard/owner`, options);
};

// TT
export const fetchDashboardPosRating = (data) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/disbursement/report/dashboard/group`, options);
};

// BC XEP HANG - NHAN VIEN
export const fetchDisbursementReportOwner = (filter, pagination) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    params: pagination,
    body: JSON.stringify(filter),
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/disbursement/report/owner/`, options, pagination);
};
export const fetchDisbursementReportOwnerExcel = (filter, pagination) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    // params: pagination,
    body: JSON.stringify(filter),
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/disbursement/report/owner/excel/`, options, pagination);
};

// BC XEP HANG - PHONG BAN
export const fetchDisbursementReportGroup = (filter, pagination) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    params: pagination,
    body: JSON.stringify(filter),
  };

  // return fetchJSON(ENP.LEAD_API + '/lead/disbursement/report/owner/', options, pagination); // dùng tạm NHANVIEN thay cho PHONGBAN
  return fetchJSON(`${ENP.LEAD_API}/lead/disbursement/report/group/`, options, pagination);
};
export const fetchDisbursementReportGroupExcel = (filter, pagination) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    // params: pagination,
    body: JSON.stringify(filter),
  };

  // return fetchJSON(ENP.LEAD_API + '/lead/disbursement/report/owner/excel/', options, pagination);// dùng tạm NHANVIEN thay cho PHONGBAN
  return fetchJSON(`${ENP.LEAD_API}/lead/disbursement/report/group/excel/`, options, pagination);
};

export const fetchActivityReport = (filter, pagination) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    // params: pagination,
    body: JSON.stringify(filter),
  };

  return fetchJSON(`${ENP.LEAD_API}/activity/report/`, options, pagination);
};

export const fetchEfficientConvertLeadReport = (filter, pagination) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    // params: pagination,
    body: JSON.stringify(filter),
  };

  return fetchJSON(`${ENP.LEAD_API}/lead-conversion/report/`, options, pagination);
};

// 1. dữ liệu - 1 bảng
export const fetchDistributeLeadReport = (filter, pagination) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    // params: pagination,
    body: JSON.stringify(filter),
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/assign/report`, options, pagination);
};

// 2. dữ liệu - tổng hợp
export const fetchDistributeLeadSummaryReport = (filter, pagination) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    // params: pagination,
    body: JSON.stringify(filter),
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/assign/report/summary`, options, pagination);
};

// 1. dữ liệu bảng
export const fetchBusinessResultReport = (filter, pagination) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    // params: pagination,
    body: JSON.stringify(filter),
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/disbursement/report`, options, pagination);
};

// 2. dữ liệu tổng hợp của bảng
export const fetchBusinessResultSummaryReport = (filter, pagination) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    // params: pagination,
    body: JSON.stringify(filter),
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/disbursement/report/summary`, options, pagination);
};

// Báo cáo hiệu quả hoạt động đại lý/đối tác
export const fetchEfficientPartnerReport = (filter, pagination) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    // params: pagination,
    body: JSON.stringify(filter),
  };

  return fetchJSON(`${ENP.LEAD_API}/partner/report`, options, pagination);
};

export const fetchCreateLeadPhoneAlerts = (number) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/alert/phone/${number}`, options);
};

export const fetchCreateLeadIdCardAlerts = (number) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/alert/id-card/${number}`, options);
};

export const fetchLeadFieldNote = (leadId) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/${leadId}/field-note`, options);
};

export const updateLeadFieldNote = (leadId, formData) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    method: 'POST',
    body: formData,
  };

  return fetchJSON(`${ENP.LEAD_API}/lead/${leadId}/field-note`, options);
};

export const fetchAllInsurance = () => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };
  return fetchJSON(`${ENP.PRODUCT_API}/insurance/get-all`, options);
};

// *******************************************************************************
// API OCR
export const fetchImageCarRegister = (imageBase) => {
  const options = {
    method: 'POST',
    headers: {
      'api-key': '7c8ba773-64cd-4ba5-a9bd-f035f06d0149',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(imageBase),
  };
  return fetchJSON('https://api-dev.nexusti.pro/ocr/reg/recognition', options);
};

// API CAR DAMAGE
export const fetchImageCarDamage = (imageFile) => {
  const formData = new FormData();
  formData.append('image', imageFile);
  const options = {
    method: 'POST',
    headers: {
      'api-key': '7c8ba773-64cd-4ba5-a9bd-f035f06d0149',
    },
    body: formData,
  };

  // 'http://123.24.142.99:9017/car_insurance'
  // return fetchJSON('https://api-dev.nexusti.pro/car/car_insurance', options);

  return fetchJSON('https://api-dev.nexusti.pro/car/car_insurance', options);
};
