import { lazy } from 'react';
import { ContentPasteSearchOutlined } from '@mui/icons-material';

// Expertise
const ExpertiseManagement = lazy(() => import('pages/expertise/expertise-manage'));

/**
 * @typedef {import('router/routes').NxRoute} NxRoute
 * @type {NxRoute}
 */
const expertiseRoutes = {
  path: '/expertise',
  name: 'menuConfig:expertiseConfig:expertise',
  icon: ContentPasteSearchOutlined,
  children: [
    {
      path: '/expertise/expertise-list',
      name: 'menuConfig:expertiseConfig:expertise-list',
      Component: ExpertiseManagement,
    },
    {
      path: '/expertise/approval',
      name: 'menuConfig:expertiseConfig:approval',
      Component: ExpertiseManagement,
    },
    {
      path: '/expertise/field-appraisal',
      name: 'menuConfig:expertiseConfig:fieldAppraisal',
      Component: ExpertiseManagement,
    },
    {
      path: '/expertise/supplemental-documentation',
      name: 'menuConfig:expertiseConfig:supplementalDocumentation',
      Component: ExpertiseManagement,
    },
    {
      path: '/expertise/disbursement-tracking',
      name: 'menuConfig:expertiseConfig:disbursementTracking',
      Component: ExpertiseManagement,
    },
  ],
};

export default expertiseRoutes;
