import { lazy } from 'react';

const Error403 = lazy(() => import('pages/other/Error403'));
const Error404 = lazy(() => import('pages/other/Error404'));

/**
 * @typedef {import('router/routes').NxRoute} NxRoute
 * @type {NxRoute[]}
 */
const errorRoutes = [
  {
    path: '/error-403',
    name: 'Login',
    Component: Error403,
    isPublic: true,
  },
  {
    path: '/error-404',
    name: 'Login',
    Component: Error404,
    isPublic: true,
  },
];

export default errorRoutes;
