import { lazy } from 'react';

const Login = lazy(() => import('pages/auth/login'));
const Logout = lazy(() => import('pages/auth/Logout'));
// const Register = lazy(() => import("pages/auth/Register"));
// const ForgetPassword = lazy(() => import("pages/auth/forget-password"));
// const Confirm = lazy(() => import("pages/auth/Confirm"));

/**
 * @typedef {import('router/routes').NxRoute} NxRoute
 * @type {NxRoute}
 */
const authRoutes = {
  path: '/account',
  name: 'Auth',
  children: [
    {
      path: '/account/login',
      name: 'Login',
      Component: Login,
      isPublic: true,
    },
    {
      path: '/account/logout',
      name: 'Logout',
      Component: Logout,
    },
    // {
    //     path: "/account/register",
    //     name: "Register",
    //     Component: Register,
    //     isPublic: true,
    // },
    // {
    //     path: "/account/confirm",
    //     name: "Confirm",
    //     Component: Confirm,
    //     isPublic: true,
    // },
    // {
    //     path: "/account/forget-password",
    //     name: "Forget Password",
    //     Component: ForgetPassword,
    //     isPublic: true,
    // },
  ],
};

export default authRoutes;
