// @flow
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { handleLoading } from 'redux/layout/actions';
import toast from 'react-hot-toast';
import * as type from './constants';
import * as actions from './actions';
import * as api from './api';

/* Account debt sagas */
function* fetchAccounts(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchAccounts, action.payload);

    yield put(actions.fetchAccountsSuccessed(response.list));
  } catch (error) {
    yield put(actions.fetchAccountsFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchPayments(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchPayments, action.payload);

    yield put(actions.fetchPaymentsSuccessed(response.list));
  } catch (error) {
    yield put(actions.fetchPaymentsFailed(error));
  }

  yield put(handleLoading(false));
}

function* fetchPartners(action) {
  const { pagination, loading } = action.payload;

  loading(true);
  try {
    const response = yield call(api.fetchPartners, pagination);
    yield put(actions.fetchPartnersSuccessed(response.list));
  } catch (error) {
    yield put(actions.fetchPartnersFailed(error));
  }
  loading(false);
}

function* fetchPartnersByFilter(action) {
  const { filter, pagination, loading } = action.payload;

  loading(true);
  try {
    const response = yield call(api.fetchPartnersByFilter, filter || {}, pagination);
    yield put(actions.fetchPartnersSuccessed(response.list));
  } catch (error) {
    yield put(actions.fetchPartnersFailed(error));
  }
  loading(false);
}

function* fetchPartnersByGroup(action) {
  // yield put(handleLoading(true));

  const { groupId, callback } = action.payload;
  try {
    const response = yield call(api.fetchPartnersByGroup, groupId);
    yield put(actions.fetchPartnersByGroupSuccessed(response));
    callback && callback(response);
  } catch (error) {
    yield put(actions.fetchPartnersByGroupFailed(error));
    callback && callback([]);
  }

  // yield put(handleLoading(false));
}

function* fetchPartnerDetail(action) {
  yield put(handleLoading(true));

  try {
    const response = yield call(api.fetchPartnerDetail, action.payload);

    yield put(actions.fetchPartnerDetailSuccessed(response));
  } catch (error) {
    yield put(actions.fetchPartnerDetailFailed(error));
  }

  yield put(handleLoading(false));
}

function* createPartner(action) {
  yield put(handleLoading(true));
  const { data, setStatus = () => {} } = action.payload;

  try {
    const response = yield call(api.createPartner, data);

    setStatus(true);
    yield put(actions.createPartnerSuccessed(response.list));
    toast.success('Tạo mới partner thành công');

    yield put(actions.fetchPartners());
  } catch (error) {
    setStatus(false);
    yield put(actions.createPartnerFailed(error));
    toast.error('Tạo mới partner thất bại');
  }

  yield put(handleLoading(false));
}

function* updatePartner(action) {
  yield put(handleLoading(true));
  const { id, data, setStatus } = action.payload;

  try {
    const response = yield call(api.updatePartner, id, data);

    setStatus(true);
    yield put(actions.updatePartnerSuccessed(response.list));
    toast.success('Cập nhật partner thành công');

    yield put(actions.fetchPartners());
  } catch (error) {
    setStatus(false);
    yield put(actions.updatePartnerFailed(error));
    toast.error('Cập nhật partner thất bại');
  }

  yield put(handleLoading(false));
}

function* fetchLeadValid(action) {
  try {
    const response = yield call(api.fetchLeadValid, action.payload);

    yield put(actions.fetchLeadValidSuccessed(response));
    // toast.success("Cập nhật partner thành công");
  } catch (error) {
    yield put(actions.fetchLeadValidFailed(error));
    // toast.error("Cập nhật partner thất bại");
  }
}

function* fetchLeadStatus(action) {
  try {
    const response = yield call(api.fetchLeadStatus, action.payload);

    yield put(actions.fetchLeadStatusSuccessed(response));
    // toast.success("Cập nhật partner thành công");
  } catch (error) {
    yield put(actions.fetchLeadStatusFailed(error));
    // toast.error("Cập nhật partner thất bại");
  }
}

function* fetchLeadReportDayCount(action) {
  try {
    const response = yield call(api.fetchLeadReportCount, action.payload);

    yield put(actions.fetchLeadReportDayCountSuccessed(response));
    // toast.success("Cập nhật partner thành công");
  } catch (error) {
    yield put(actions.fetchLeadReportDayCountFailed(error));
    // toast.error("Cập nhật partner thất bại");
  }
}

function* fetchLeadReportDisbursement(action) {
  try {
    const response = yield call(api.fetchLeadDisbursement, action.payload);

    yield put(actions.fetchLeadDisbursementSuccessed(response));
    // toast.success("Cập nhật partner thành công");
  } catch (error) {
    yield put(actions.fetchLeadDisbursementFailed(error));
    // toast.error("Cập nhật partner thất bại");
  }
}

function* fetchEffectivePartner(action) {
  try {
    const response = { totalDisbursedLead: 0 };
    // const response = yield call(api.fetchEffectivePartner, action.payload);

    yield put(actions.fetchEffectivePartnerSuccessed(response));
  } catch (error) {
    yield put(actions.fetchEffectivePartnerFailed(error));
  }
}

export function* watchPartner() {
  yield takeLatest(type.FETCH_PARTNERS_BY_GROUP, fetchPartnersByGroup);
  yield takeLatest(type.FETCH_ACCOUNTS, fetchAccounts);
  yield takeLatest(type.FETCH_PARTNERS, fetchPartners);
  yield takeLatest(type.FETCH_PARTNERS_BY_FILTER, fetchPartnersByFilter);
  yield takeLatest(type.FETCH_PARTNER_DETAIL, fetchPartnerDetail);
  yield takeLatest(type.FETCH_PAYMENTS, fetchPayments);
  yield takeLatest(type.CREATE_PARTNER, createPartner);
  yield takeLatest(type.UPDATE_PARTNER, updatePartner);
  yield takeLatest(type.FETCH_LEAD_VALID, fetchLeadValid);
  yield takeLatest(type.FETCH_LEAD_STATUS, fetchLeadStatus);
  yield takeLatest(type.FETCH_LEAD_REPORT_DAY_COUNT, fetchLeadReportDayCount);
  yield takeLatest(type.FETCH_LEAD_DISBURSEMENT, fetchLeadReportDisbursement);
  yield takeLatest(type.GET_EFFECTIVE_PARTNER, fetchEffectivePartner);
}

function* partnerSaga() {
  yield all([fork(watchPartner)]);
}

export default partnerSaga;
