import './index.scss';
import logo from 'assets/images/nexusti100.png';

function Loader() {
  return (
    <div className="loader">
      <div className="loader__content">
        <img src={logo} alt="" height="60" />
      </div>
      <div className="loader__icon">
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

export default Loader;
