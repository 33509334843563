import { lazy } from 'react';
import { DashboardOutlined } from '@mui/icons-material';

/**
 * @typedef {import('router/routes').NxRoute} NxRoute
 */

const CrmDashboard = lazy(() => import('pages/dashboard'));

/** @type {NxRoute} */
const dashboardRoute = {
  path: '/dashboard',
  name: 'menuConfig:dashboard',
  Component: CrmDashboard,
  icon: DashboardOutlined,
  exact: true,
};

export default dashboardRoute;
