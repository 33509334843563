import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { flowRight as compose } from 'lodash-es';
import { setWebPhone } from 'redux/actions';

import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';
import CloseIcon from '@mui/icons-material/Close';
import BackspaceIcon from '@mui/icons-material/Backspace';
import MutedIcon from '@mui/icons-material/VolumeOff';
import UnmutedIcon from '@mui/icons-material/VolumeUp';
import PauseIcon from '@mui/icons-material/Pause';
import PlayIcon from '@mui/icons-material/PlayArrow';
import CircleIcon from '@mui/icons-material/FiberManualRecord';
import {
  ActionButton,
  Box,
  Button,
  ButtonsContainer,
  CallButton,
  CallInfo,
  DeleteButton,
  EndCallButton,
  Input,
  StatusInfo,
  ToggleButton,
  TypeSelect,
} from './dialer-components';
import OmicxDialerComponent from './omicx/omicx-dialer';
import PbxDialerComponent from './pbx/pbx-dialer';
import DialerContext from './dialer-context';

export const WEBPHONE_SDK = {
  OMICX: 'OMICX',
  PBX: 'PBX',
};

export const WEBPHONE_PROVIDER = {
  F88: 'F88',
  CGV: 'CGV',
};

function NexustiDialerProvider({
  webPhoneList,
  // target, isCall,
  // setIsCall = () => { },
  // setCallId = () => { },
  // setCallProvider = () => { },
  onCalling = () => {},
  onAvailable = () => {},
  children,
}) {
  const dispatch = useDispatch();
  const updateWebPhone = compose(dispatch, setWebPhone);

  const webPhone = useSelector((state) => state.Auth.webPhone);

  /* UI property */
  const [disabled, setDisabled] = useState(true);
  const [opened, setOpened] = useState(true);
  const [number, setNumber] = useState('');

  /* Last call info */
  const [callId, setCallId] = useState();
  const [callProvider, setCallProvider] = useState();

  /* Current info */
  // const [currentType, setCurrentType] = useState(webPhoneList[0].type);
  const [currentProvider, setCurrentProvider] = useState(webPhoneList[0].provider);
  const [currentCallUser, setCurrentCallUser] = useState(webPhoneList[0].username);

  const [callRequest, setCallRequest] = useState(false);
  const [answerRequest, setAnswerRequest] = useState(false);
  const [endCallRequest, setEndCallRequest] = useState(false);
  const [muteRequest, setMuteRequest] = useState(false);
  const [holdRequest, setHoldRequest] = useState(false);
  const [unmuteRequest, setUnmuteRequest] = useState(false);
  const [unholdRequest, setUnholdRequest] = useState(false);

  const [isCalling, setIsCalling] = useState(false);
  const [isIncoming, setIsIncoming] = useState(false);

  const [isMuted, setIsMuted] = useState(false);
  const [isHeld, setIsHeld] = useState(false);

  const [stateLabel, setStateLabel] = useState('');
  const [totalSeconds, setTotalSeconds] = useState(0);

  const buttons = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];

  useEffect(() => {
    if (!webPhone) {
      updateWebPhone({});
    }
  }, []);

  useEffect(() => {
    if (!currentCallUser) {
      setCurrentCallUser(webPhoneList[0].username);
    }
  }, [webPhoneList]);

  useEffect(() => {
    if (isCalling) {
      onCalling();
      setOpened(true);
    } else {
      onAvailable();
    }
  }, [isCalling]);

  const getWebPhoneComponents = () => {
    return webPhoneList.map((config) => getWebPhoneComponent(config));
  };

  const getWebPhoneComponent = (config) => {
    const { type } = config;
    const { provider } = config;
    const { username } = config;
    const { password } = config;
    const { domain } = config;
    if (!type || !provider || !username || !password || !domain) return;

    const wb = webPhone[provider];

    const updateWebPhone = (webPhone) => updateWebPhoneObject(provider, webPhone);
    const setConnected = (connected) => updateWebPhoneConnected(provider, connected);

    const active = provider === currentProvider;

    const callRq = active && callRequest;

    const props = {
      username,
      password,
      domain,
      provider,
      webPhone: wb,
      isCalling,
      updateWebPhone,
      onCallId,
      setConnected,
      setIsCalling,
      setIsIncoming,
      setIsMuted,
      setIsHeld,
      setStateLabel,
      setTotalSeconds,
      callRequest: callRq,
      answerRequest,
      endCallRequest,
      muteRequest,
      unmuteRequest,
      holdRequest,
      unholdRequest,
      setCallRequest,
      setAnswerRequest,
      setEndCallRequest,
      setMuteRequest,
      setUnmuteRequest,
      setHoldRequest,
      setUnholdRequest,
    };

    switch (config.type) {
      case WEBPHONE_SDK.OMICX:
        return <OmicxDialerComponent {...props} key={provider} />;
      case WEBPHONE_SDK.PBX:
        return <PbxDialerComponent {...props} key={provider} />;
      default:
        return '';
    }
  };

  const onCallId = (callId) => {
    setCallId(callId);
    setCallProvider(currentProvider);
  };

  const updateWebPhoneObject = (provider, webPhoneObject) => {
    webPhone[provider] = webPhoneObject;
    updateWebPhone({ ...webPhone });
  };

  const updateWebPhoneConnected = (provider, connected) => {
    webPhone[provider].connected = connected;
    updateWebPhone({ ...webPhone });
  };

  // useEffect(() => {
  //     if (target && isCall) {
  //         call(target);
  //     }
  //     setIsCall(false);
  // }, [target, isCall]);

  const call = (target) => {
    if (webPhone) {
      setNumber(target);
      setOpened(true);
      setCallRequest({ target });
    }
  };

  const toggleDialer = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    setOpened(!opened);
  };

  const onDialerClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  const deleteNumber = () => {
    setNumber(number.slice(0, -1));
  };

  const renderDialer = () => {
    return (
      <Box opened={opened} onClick={onDialerClick}>
        <div className="d-flex mb-1">
          <StatusInfo className="flex-fill">
            <CircleIcon
              className={webPhone[currentProvider]?.connected ? 'text-success' : 'text-danger'}
              fontSize="small"
            />
            {webPhone[currentProvider]?.connected ? 'Đã kết nối ' : 'Chưa kết nối '}({currentCallUser})
          </StatusInfo>
          <TypeSelect
            onChange={(e) => {
              const config = webPhoneList[e.target.value];
              setCurrentProvider(config.provider);
              setCurrentCallUser(config.username);
            }}
          >
            {webPhoneList.map((config, index) => (
              <option value={index} key={index}>
                {config.provider}
              </option>
            ))}
          </TypeSelect>
        </div>

        <Input placeholder="0000-0000-0000" value={number} onChange={(e) => setNumber(e.target.value)} />

        <ButtonsContainer>
          {buttons.map((char) => (
            <Button key={char} onClick={() => setNumber(number + char)}>
              {char}
            </Button>
          ))}
        </ButtonsContainer>

        <CallButton onClick={() => setCallRequest({ target: number })} disabled={!number}>
          <CallIcon size={24} />
        </CallButton>
        <DeleteButton onClick={() => deleteNumber()}>
          <BackspaceIcon size={24} />
        </DeleteButton>
      </Box>
    );
  };

  const renderTimer = () => {
    return `${pad(parseInt(totalSeconds / 60))}:${pad(totalSeconds % 60)}`;
  };

  const pad = (val) => {
    const valString = `${val}`;
    if (valString.length < 2) {
      return `0${valString}`;
    }
    return valString;
  };

  const renderCallingDialer = () => {
    return (
      <Box opened={opened} onClick={onDialerClick}>
        <CallInfo>
          <p>to: {number}</p>
          <p>{stateLabel}</p>
          <p>{renderTimer()}</p>
        </CallInfo>
        <ButtonsContainer>
          {isMuted ? (
            <ActionButton onClick={() => setUnmuteRequest(true)}>
              <MutedIcon />
            </ActionButton>
          ) : (
            <ActionButton onClick={() => setMuteRequest(true)}>
              <UnmutedIcon />
            </ActionButton>
          )}
          {isHeld ? (
            <ActionButton onClick={() => setUnholdRequest(true)}>
              <PlayIcon />
            </ActionButton>
          ) : (
            <ActionButton onClick={() => setHoldRequest(true)}>
              <PauseIcon />
            </ActionButton>
          )}
        </ButtonsContainer>

        {isIncoming ? (
          <CallButton onClick={() => setAnswerRequest(true)} disabled={false}>
            <CallIcon size={24} />
          </CallButton>
        ) : (
          ''
        )}
        <EndCallButton onClick={() => setEndCallRequest(true)}>
          <CallEndIcon size={24} />
        </EndCallButton>
      </Box>
    );
  };

  const renderToggleDialer = () => {
    return (
      <ToggleButton opened={opened} onClick={toggleDialer}>
        {opened && <CloseIcon size={24} />}
        {!opened && <CallIcon size={24} />}
      </ToggleButton>
    );
  };

  const getChildContext = () => {
    return {
      callId,
      callProvider,
      call,
      setDisabled,
    };
  };

  return (
    <DialerContext.Provider value={getChildContext()}>
      {children}

      {webPhone ? (
        <>
          {getWebPhoneComponents()}
          {!disabled || isCalling ? (
            <>
              {renderToggleDialer()}
              {isCalling ? renderCallingDialer() : renderDialer()}
            </>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
    </DialerContext.Provider>
  );
}

export default NexustiDialerProvider;
