import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENP } from 'constants/api';
import { ListWithPagination } from 'redux/types';
import { getAccessToken } from 'helpers/authUtils';
import { LeadDetail } from './types';

export const leadApi = createApi({
  reducerPath: 'leadApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ENP.LEAD_API,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${getAccessToken()}`);
      return headers;
    },
  }),
  tagTypes: [
    'leadList',
    'leadDetail',
    'leadAlerts',
    'leadNotes',
    'leadEvents',
    'leadTaskLogs',
    'leadActivities',
    'leadAioActivities',
    'leadDemoData',
  ],
  endpoints: (builder) => ({
    filterLeads: builder.query({
      query: ({ filter, pagination }) => ({
        url: `lead/filter`,
        method: 'POST',
        params: pagination,
        body: filter,
      }),
      transformResponse: (response: ListWithPagination<LeadDetail>) => response.list,
      providesTags: ['leadList'],
    }),
    fetchLeadDetail: builder.query<LeadDetail, string>({
      query: (id) => `lead/${id}`,
      providesTags: ['leadDetail'],
    }),
    fetchLeadAlerts: builder.query<any, string>({
      query: (id) => `alert/${id}`,
      providesTags: ['leadAlerts'],
    }),

    createLead: builder.mutation({
      query: (data) => ({
        url: `lead/create`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['leadList'],
    }),
    updateLead: builder.mutation({
      query: (data) => ({
        url: `lead/${data.id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['leadDetail', 'leadAlerts'],
    }),
    completeCurrentStepLead: builder.mutation({
      query: ({ id, data }) => ({
        url: `lead/${id}/complete`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['leadDetail', 'leadAlerts', 'leadTaskLogs', 'leadAioActivities'],
    }),

    /* Activity */

    fetchLeadNotes: builder.query({
      query: ({ id, params }) => ({
        url: `note/${id}/get-all/paging`,
        params,
      }),
      providesTags: ['leadNotes'],
    }),
    fetchLeadEvents: builder.query({
      query: ({ id, params }) => ({
        url: `event/${id}`,
        params,
      }),
      providesTags: ['leadEvents'],
    }),
    fetchLeadTaskLogs: builder.query({
      query: ({ id, params }) => ({
        url: `lead/${id}/flow/logs`,
        params,
      }),
      providesTags: ['leadTaskLogs'],
    }),
    fetchLeadActivities: builder.query({
      query: ({ id, params }) => ({
        url: `activity/${id}`,
        params,
      }),
      providesTags: ['leadActivities'],
    }),
    fetchLeadAioActivities: builder.query({
      query: ({ id, params }) => ({
        url: `activity/aio/${id}`,
        params,
      }),
      providesTags: ['leadAioActivities'],
    }),

    createLeadNote: builder.mutation({
      query: (data) => ({
        url: `note/create`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['leadNotes', 'leadAioActivities'],
    }),
    createLeadEvent: builder.mutation({
      query: (data) => ({
        url: `event/create`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['leadEvents', 'leadAioActivities'],
    }),
    createLeadActivity: builder.mutation({
      query: (data) => ({
        url: `activity/create`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['leadActivities', 'leadAioActivities'],
    }),

    /* Demo only data */
    fetchLeadDemoData: builder.query({
      query: (id) => `demo/${id}`,
      providesTags: ['leadDemoData'],
    }),
    updateLeadDemoData: builder.mutation({
      query: ({ id, data }) => ({
        url: `demo/${id}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['leadDemoData'],
    }),
  }),
});

export const {
  useFilterLeadsQuery,
  useFetchLeadDetailQuery,
  useFetchLeadAlertsQuery,

  useCreateLeadMutation,
  useUpdateLeadMutation,
  useCompleteCurrentStepLeadMutation,

  useFetchLeadNotesQuery,
  useFetchLeadEventsQuery,
  useFetchLeadTaskLogsQuery,
  useFetchLeadActivitiesQuery,
  useFetchLeadAioActivitiesQuery,

  useCreateLeadNoteMutation,
  useCreateLeadEventMutation,
  useCreateLeadActivityMutation,

  useFetchLeadDemoDataQuery,
  useUpdateLeadDemoDataMutation,
} = leadApi;
